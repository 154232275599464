// 3rd party imports
import * as React from 'react';

// components
import { ExpandFooter } from './expand-footer';
import { Header } from './header';
// import { CookieBanner } from 'components/layout/cookie-banner';

interface LayoutProps {
  children: React.ReactNode;
  centered?: boolean;
}

export const Layout: React.FunctionComponent<LayoutProps> = ({ children, centered }) => {
  const cookieLink = React.useRef();

  return (
    <div className="Layout">
      <Header />
      <section className={`Layout__body ${centered ? 'Layout__body--centered' : ''}`}>{children}</section>
      <ExpandFooter cookieLinkRef={cookieLink} />
    </div>
  );
};
