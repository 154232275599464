import { EgClickstreamData } from '@shared-ui/clickstream-analytics-context/dist/commonjs/eg-clickstream/use-eg-clickstream';

import {
  baseEventFragmentPayload,
  baseExperienceFragmentPayload,
  creatorVideoBaseEventFragmentPayload
} from './click-stream-utils';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { useCallback } from 'react';

export enum EventType {
  PAGE_VIEW = 'Page View',
  INTERACTION = 'Interaction',
  IMPRESSION = 'Impression',
  OUTCOME = 'Outcome',
}

export interface EventObjectType {
  [key: string]: unknown;
}

type Track = (data: EgClickstreamData) => void;

export const affiliateHubHomePresentedEvent = (track: Track): void => {
  const openEvent = {
    event: {
      ...baseEventFragmentPayload,
      event_type: EventType.PAGE_VIEW,
      event_name: 'affiliates_hub_home.viewed',
    },
    experience: {
      ...baseExperienceFragmentPayload,
      page_name: 'affiliate_hub_home',
      page_name_detailed: 'Affiliate Hub Home',
    },
  };
  track(openEvent);
};

export const unsubscribePresentedEvent = (track: Track): void => {
  const openEvent = {
    event: {
      ...baseEventFragmentPayload,
      event_type: EventType.PAGE_VIEW,
      event_name: 'affhub_unsubscribe.viewed',
    },
    experience: {
      ...baseExperienceFragmentPayload,
      page_name: 'affiliate_unsubscribe',
      page_name_detailed: 'Unsubscribe',
    },
  };
  track(openEvent);
};

export const unsubscribeSelectedEvent = (track: Track): void => {
  const openEvent = {
    event: {
      ...baseEventFragmentPayload,
      event_type: EventType.INTERACTION,
      event_name: 'affhub_unsubscribe_request.selected',
    },
    experience: {
      ...baseExperienceFragmentPayload,
      page_name: 'unsubscribe',
      page_name_detailed: 'Unsubscribe',
    },
  };
  track(openEvent);
};

export const unsubscribeSucceedEvent = (track: Track): void => {
  const openEvent = {
    event: {
      ...baseEventFragmentPayload,
      event_type: EventType.OUTCOME,
      event_name: 'affhub_unsubscribe_request.succeeded',
    },
    experience: {
      ...baseExperienceFragmentPayload,
      page_name: 'unsubscribe',
      page_name_detailed: 'Unsubscribe',
    },
  };
  track(openEvent);
};

export const unsubscribeFailedEvent = (track: Track): void => {
  const openEvent = {
    event: {
      ...baseEventFragmentPayload,
      event_type: EventType.OUTCOME,
      event_name: 'affhub_unsubscribe_request.failed',
    },
    experience: {
      ...baseExperienceFragmentPayload,
      page_name: 'unsubscribe',
      page_name_detailed: 'Unsubscribe',
    },
  };
  track(openEvent);
};

export const headerLogoSelectedEvent = (track: Track, page?: string): void => {
  const openEvent = {
    event: {
      ...baseEventFragmentPayload,
      event_name: 'affhub_header_logo.selected',
    },
    experience: {
      ...baseExperienceFragmentPayload,
      page_name: page ?? baseExperienceFragmentPayload.page_name,
    },
  };
  track(openEvent);
};

export const headerSigninSelectedEvent = (track: Track, page?: string): void => {
  const openEvent = {
    event: {
      ...baseEventFragmentPayload,
      event_name: 'affhub_header_sign_in.selected',
    },
    experience: {
      ...baseExperienceFragmentPayload,
      page_name: page ?? baseExperienceFragmentPayload.page_name,
    },
  };
  track(openEvent);
};

export const homeJoinNowSelectedEvent = (track: Track, actionLocation?: string): void => {
  const openEvent = {
    event: {
      ...baseEventFragmentPayload,
      event_name: 'affhub_home_join_now.selected',
      action_location: actionLocation,
    },
    experience: {
      ...baseExperienceFragmentPayload,
      page_name: 'affiliate_hub_home',
    },
  };
  track(openEvent);
};

const clickstreamEventBuilder = (eventOverride, additionalContext?) => {
  const openEvent: EventObjectType = {
    event: {
      ...creatorVideoBaseEventFragmentPayload,
      action_reason: sessionStorage.getItem('userType'),
      ...eventOverride
    },
    ...(additionalContext && additionalContext),
    booking: {
      order_id: sessionStorage.getItem('orderNumber'),
      pos_order_reference_number: sessionStorage.getItem('itinerary'),
      transaction_status: 'Success'
    },
    trip: {
      trip_detail_list: [
        {
          trip_status: sessionStorage.getItem('tripStatus')
        }
      ]
    },
    product_list: [
      {
        product_id: sessionStorage.getItem('propertyId')
      }
    ]
  };

  return openEvent;
};

export const useCommonClickStreamEvent = () => {
  const track = useEgClickstream();

  const trackEvent = useCallback(
    (eventOverride, additionalContext?) => {
      try {
        const openEvent = clickstreamEventBuilder(eventOverride, additionalContext);
        track(openEvent);
      } catch (e) {
        // Analytics tracking as a side effect, should not break functionality if failed
      }
    },
    [track],
  );

  return { trackEvent };
};
