// 3rd party imports
import * as React from 'react';
import { FunctionComponent } from 'react';

// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { LocalizedText } from '@shared-ui/localization-context';

// ui
import { UitkPrimaryButton, UitkButtonSize } from 'uitk-react-button';

import { useStores } from 'src/hooks';
import { homeJoinNowSelectedEvent, makeLocaleParam } from 'src/util';

interface JoinNowActionProps {
  size?: UitkButtonSize.SMALL | UitkButtonSize.MEDIUM | UitkButtonSize.LARGE;
  actionLocation?: string;
}

const JoinNowAction: FunctionComponent<JoinNowActionProps> = ({ size, actionLocation }) => {
  const track = useEgClickstream();
  const { context } = useStores();

  return (
    <>
      <UitkPrimaryButton
        tag="a"
        href={`/${makeLocaleParam(context.locale)}/signup`}
        data-cy="sign-up-link"
        size={size ? size : UitkButtonSize.MEDIUM}
        onClickCapture={() => {
          homeJoinNowSelectedEvent(track, actionLocation);
        }}
      >
        <LocalizedText message="common.join_now" />
      </UitkPrimaryButton>
    </>
  );
};

export { JoinNowAction };
