// 3rd party imports
import * as React from 'react';

//Uitk
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutGrid } from 'uitk-react-layout-grid';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkPrimaryButton } from 'uitk-react-button';
import { Carousel } from 'components/creator-video';
import { LinkWithQuery } from 'components/shared/link-with-query';

import { useCommonClickStreamEvent, EventType } from 'src/util';

export interface ThreeTileMobileProps {
  heading: string;
  subHeading: string;
  gridContent: any;
  isBenefit?: boolean;
  innerRef: any;
}
export function ThreeTileMobile({
  heading,
  subHeading,
  gridContent,
  isBenefit = false,
  innerRef,
}: ThreeTileMobileProps): JSX.Element {
  const { trackEvent } = useCommonClickStreamEvent();
  function carouselLayout() {
    return (
      <Carousel className="text">
        {gridContent.map((gridItem, ind) => (
          <div className="CVCarousel__flex-item" key={`cc-${isBenefit ? 'benefits' : 'how-it-works'}-griditem-${ind}`}>
            <div className="CVThreeTile__grid-item">
              <img className={'cc-three-tile__small-grid-svg'} src={gridItem.svg} />
              <UitkHeading size={5}>{gridItem.header}</UitkHeading>
              <UitkText className="cc-text-neutral-1-600" size={400}>
                {gridItem.paragraph}
              </UitkText>
            </div>
          </div>
        ))}
      </Carousel>
    );
  }

  const handleRedirectAnalytics = () => {
    trackEvent(
      {
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: isBenefit ? 'benefits-section-button' : 'how-it-works-section-button',
      },
      {
        additional_context: {
          user_interface: {
            component_element_text: isBenefit ? 'benefits section button' : 'how it works section button',
          },
        },
      },
    );
  };

  function desktopLayout() {
    return (
      <UitkLayoutGrid
        className={`CV${isBenefit ? 'Benefits' : 'HowItWorks'}__grid`}
        columns={isBenefit ? 1 : gridContent.length}
        rows={isBenefit ? gridContent.length : 1}
      >
        {gridContent.map((gridItem, ind) => (
          <div className="CVThreeTile__grid-item" key={`cc-${isBenefit ? 'benefits' : 'how-it-works'}-griditem-${ind}`}>
            <img className="cc-three-tile__small-grid-svg" src={gridItem.svg} />
            <UitkHeading size={5}>{gridItem.header}</UitkHeading>
            <UitkText className="cc-text-neutral-1-600" size={400}>
              {gridItem.paragraph}
            </UitkText>
          </div>
        ))}
      </UitkLayoutGrid>
    );
  }

  return (
    <div className="CVContainer" ref={innerRef}>
      <UitkLayoutFlex
        direction="column"
        alignItems="center"
        alignContent="center"
        className={`CVSection__padding CVThreeTile__section CVSection__width`}
        id={isBenefit ? 'benefits' : 'how-it-works'}
      >
        <UitkLayoutFlex direction="column" className="CVGap-16" tag="header">
          <UitkText className="cc-text-neutral-1-600" size={400} weight="medium" align="center">
            {subHeading}
          </UitkText>
          <UitkHeading size={4} tag="h2" align="center">
            {heading}
          </UitkHeading>
        </UitkLayoutFlex>
        <div className={`CV${isBenefit ? 'Benefit' : 'HowItWorks'}__content`}>
          {!isBenefit ? carouselLayout() : desktopLayout()}
        </div>

        <LinkWithQuery to="/en-us/creator-video/submit">
          <UitkPrimaryButton className="cc-three-tile__button" onClick={handleRedirectAnalytics}>
            {isBenefit ? "I'm ready" : 'Upload my content'}
          </UitkPrimaryButton>
        </LinkWithQuery>
      </UitkLayoutFlex>
    </div>
  );
}
