import * as React from 'react';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkMenu, UitkMenuContainer, UitkMenuList, UitkMenuTrigger } from 'uitk-react-menu';
import { UitkText } from 'uitk-react-text';
import { useLocation } from 'react-router-dom';

import { SupportedLocale, supportedLocales as defaultLocales } from 'src/config/supported-locales';
import { useStores } from 'src/hooks';
import { makeLocaleParam } from 'src/util';

export interface LanguageSelectorProps {
  subSupportedLocales?: SupportedLocale[];
}

export const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = ({ subSupportedLocales }) => {
  const { context } = useStores();
  const locale = makeLocaleParam(context.locale);
  const location = useLocation();

  let supportedLocales;
  if (subSupportedLocales) {
    supportedLocales = subSupportedLocales;
  } else {
    // tslint:disable-next-line:no-shadowed-variable
    supportedLocales = defaultLocales;
  }

  const currentLang =
    supportedLocales?.find((locale) => {
      return locale.langCode === locale;
    }) ?? supportedLocales[0];

  // But we already put it in a cookie in the context...
  React.useEffect(() => {
    const storedLocale = localStorage.getItem('locale');
    if (!storedLocale || locale !== storedLocale) {
      localStorage.setItem('locale', locale);
    }
  }, []);

  // Set state
  const [activeLabel, setActiveLabel] = React.useState(currentLang?.langLabel);
  const [open, setOpen] = React.useState(false);

  let pathWithoutLocale: string;
  let path: string;

  // Do this for every page other than the first homepage load
  if (location.pathname !== '/home') {
    // Get path from the route, strip out locale
    const pathArray = location.pathname.split('/');
    const param = location.search;

    if (pathArray) {
      // take out first pieces of URL '' and '/xx-xx/'
      pathArray.splice(0, 2);
      pathWithoutLocale = pathArray && pathArray.join('/');
    }

    path = param ? pathWithoutLocale + param : pathWithoutLocale;
    // Homepage starts without locale prefix so don't try remove
  } else {
    path = 'home';
  }

  const langList =
    supportedLocales &&
    supportedLocales.map((lang, i) => {
      return {
        attributes: {
          role: 'menuitem',
          tabindex: i + 1,
        },
        label: lang.langLabel,
        className: 'uitk-list-item ' + (currentLang.langId === lang.langId ? 'langSelected' : ''),
        href: `/${lang.langCode}/${path}`,
        typeAnchor: true,
        onClick: (e) => handleItemClick(e, lang.langLabel),
        analytics: {
          id: 'affhub_language_switcher.submitted',
          callback() {
            analyticsTrackEvents('affhub_language_switcher.submitted');
          },
        },
      };
    });

  function handleItemClick(e, label) {
    setActiveLabel(label);
    setOpen(false);
  }

  function handleTriggerClicked() {
    setOpen(!open);
  }

  const analyticsTrackEvents = (rfrr: string) => {
    // analyticsEvent(analytics, userAuth, pzStore, rfrr, linkname);
    console.log(rfrr);
  };

  return (
    <UitkMenu isOpen={open} onTriggerClick={handleTriggerClicked}>
      <UitkMenuTrigger>
        <UitkTertiaryButton>
          <UitkIcon name="language" className="Language__icon" />
          <UitkText size={300} className="visible-desktop--large">
            {activeLabel}
          </UitkText>
        </UitkTertiaryButton>
      </UitkMenuTrigger>
      <UitkMenuContainer autoPosition>
        <UitkMenuList items={langList} />
      </UitkMenuContainer>
    </UitkMenu>
  );
};
