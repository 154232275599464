import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useCreatorVideoSetup() {
  const location = useLocation();

  useEffect(() => {
    const shouldUpdateSessionStorage = !sessionStorage.getItem('source')
      || !sessionStorage.getItem('itinerary')
      || !sessionStorage.getItem('orderNumber')
      || !sessionStorage.getItem('propertyId')
      || !sessionStorage.getItem('tripStatus')
      || !sessionStorage.getItem('userType');

    if (shouldUpdateSessionStorage) {
      const urlSearchParams = new URLSearchParams(location.search);

      let source = sessionStorage.getItem('source') ?? urlSearchParams.get('source');
      const itinerary = sessionStorage.getItem('itinerary') ?? urlSearchParams.get('itinerary');
      const orderNumber = sessionStorage.getItem('orderNumber') ?? urlSearchParams.get('orderNumber');
      const propertyId = sessionStorage.getItem('propertyId') ?? urlSearchParams.get('propertyId');
      let tripStatus = sessionStorage.getItem('tripStatus') ?? urlSearchParams.get('tripStatus');

      if (source === null || source.trim() === '') {
        source = 'LANDING_PAGE';
        sessionStorage.setItem('userType', 'creator');
      } else if (source.match(/reviews/i)) {
        tripStatus = 'IN_TRIP';
      }

      sessionStorage.setItem('source', source);
      sessionStorage.setItem('itinerary', itinerary);
      sessionStorage.setItem('orderNumber', orderNumber);
      sessionStorage.setItem('propertyId', propertyId);
      sessionStorage.setItem('tripStatus', tripStatus);
      sessionStorage.setItem('userType', sessionStorage.getItem('userType') ?? 'traveler');
    }
  }, [location]);
}
