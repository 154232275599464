// 3rd party imports
import * as React from 'react';

// components
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';

// store
import { LanguageSelector } from '../language-selector';

const HeaderLite: React.FunctionComponent = () => {
  return (
    <GlobalNavigationSiteHeader>
      <UitkSpacing padding={{ inline: 'four' }}>
        <UitkLayoutFlex className="container ErrorMessage__header" alignItems="center" justifyContent="space-between">
          <UitkLayoutFlexItem>
            <img
              className="Header__logo"
              src="/images/logos/eg-affiliate-program-logo-blue.svg"
              alt="Expedia Group Affiliate Program"
            />
          </UitkLayoutFlexItem>

          <UitkLayoutFlexItem>
            <nav className="Header__nav">
              <UitkLayoutFlex
                alignItems="center"
                className="no-bullet"
                direction="row"
                space="three"
                tag="ul"
                wrap="wrap"
              >
                <li>
                  <LanguageSelector />
                </li>
              </UitkLayoutFlex>
            </nav>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkSpacing>
    </GlobalNavigationSiteHeader>
  );
};

export default HeaderLite;
export { HeaderLite };
