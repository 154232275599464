// 3rd party imports
import * as React from 'react';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';

// ui
import { GlobalNavigationSiteHeader } from 'uitk-react-global-navigation';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkSpacing } from 'uitk-react-spacing';

import { useStores } from 'src/hooks';
import { headerLogoSelectedEvent, makeLocaleParam } from 'src/util';
import { LanguageSelector } from '../language-selector';
import { SigninAndJoinNav } from './signin-and-join-nav';

const Header: FunctionComponent = () => {
  const track = useEgClickstream();
  const { context } = useStores();

  return (
    <GlobalNavigationSiteHeader className="Header">
      <UitkSpacing padding={{ inline: 'four' }}>
        <UitkLayoutFlex className="container" alignItems="center" justifyContent="space-between">
          <UitkLayoutFlexItem>
            <div>
              <UitkLink onClickCapture={() => headerLogoSelectedEvent(track)}>
                <Link to={`/${makeLocaleParam(context.locale)}/home`}>
                  <img
                    className="Header__logo"
                    src="/images/logos/eg-affiliate-program-logo-blue.svg"
                    alt="Expedia Group Affiliate Program"
                  />
                </Link>
              </UitkLink>
            </div>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem>
            <nav className="Header__nav">
              <UitkLayoutFlex
                alignItems="center"
                className="no-bullet"
                direction="row"
                space="three"
                tag="ul"
                wrap="wrap"
              >
                <li>
                  <LanguageSelector />
                </li>
                <li>
                  <SigninAndJoinNav />
                </li>
              </UitkLayoutFlex>
            </nav>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkSpacing>
    </GlobalNavigationSiteHeader>
  );
};

export { Header };
