// 3rd party imports
import React, { FunctionComponent } from 'react';

// Bernie
import { LocalizedComponentProps } from 'bernie-l10n/dist/components';

// UITK
import { UitkButtonSize, UitkFloatingButton, UitkSecondaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLink } from 'uitk-react-link';
import { classNames } from 'uitk-react-utils';
import { ILocalization } from '@shared-ui/localization-context';

interface DirectWordProps extends LocalizedComponentProps {
  pageName: string;
  display?: string;
  text?: string;
  showIcon?: boolean;
  locale?: string;
  l10n: ILocalization;
}

const DirectWord: FunctionComponent<DirectWordProps> = ({ pageName, display, text, showIcon, locale, l10n }) => {
  let url: string;

  const classes = classNames({
    'DirectWord--inline': display === 'inline',
    'DirectWord--floating': display === 'floating',
  });

  const defaultText = l10n.formatText('common.feedback');

  // Fix SSR error where window doesn't exit
  if (typeof window !== 'undefined') {
    url = window.location.href;
  }

  const data = {
    pagename: pageName,
    url,
  };

  const metaData = encodeURIComponent(JSON.stringify(data));
  const dwLocale = locale.toLowerCase().replace('_', '-');
  const dwLink = `https://www.directword.io/survey/domain=affiliates.expediagroup.com/locale=${dwLocale}?metadata=${metaData}`;

  function handleClick() {
    window.open(dwLink);
  }

  switch (display) {
    case 'block':
      return (
        <UitkSecondaryButton className={classes} size={UitkButtonSize.SMALL} isFullWidth={true} onClick={handleClick}>
          {showIcon && <UitkIcon name="comment" />}
          {text || defaultText}
        </UitkSecondaryButton>
      );

    default:
    case 'inline':
      return (
        <UitkSecondaryButton className={classes} onClick={handleClick}>
          {showIcon && <UitkIcon name="comment" />}
          {text || defaultText}
        </UitkSecondaryButton>
      );

    case 'floating':
      return (
        <UitkFloatingButton className={classes} onClick={handleClick}>
          {showIcon && <UitkIcon name="comment" />}
          {text || defaultText}
        </UitkFloatingButton>
      );

    case 'link':
      return (
        <UitkLink className="DirectWord__link" size="large" inline={true}>
          <a href={dwLink} target="_blank" rel="noopener noreferrer">
            {text || defaultText}
          </a>
        </UitkLink>
      );
  }
};

export { DirectWord };
