import React, { useId } from 'react';

import { UitkCarousel } from 'uitk-react-carousel';
import { UitkText, UitkHeading } from 'uitk-react-text';
import { UitkTertiaryButton } from 'uitk-react-button';
import { UitkLayoutGrid } from 'uitk-react-layout-grid';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';

import { useStores } from 'src/hooks';

import { makeLocaleParam } from 'src/util';

export function HowItWorks() {

  const { context } = useStores();

  const benefits = [
    {
      icon: '/images/how-it-works-one.svg',
      title: 'Become an affiliate',
      content:
      <UitkText size={400}>Join our affiliate program to start sharing your videos. You’ll also get access to other creator tools that’ll help you earn even more commission!</UitkText>,
    },
    {
      icon: '/images/how-it-works-two.svg',
      title: 'Share your videos',
      content: (
        <UitkText size={400}>
          {`We&apos;re looking for video quality, property highlights, and engagement potential, among other criteria. Read our `}
          <UitkTertiaryButton
            type="button"
            className="CVVcg__button"
            tag="a" target="_blank" href={`/${makeLocaleParam(context.locale)}/travel-video/video-guidelines`}
          >
            Content Guidelines
          </UitkTertiaryButton>.
        </UitkText>
      ),
    },
    {
      icon: '/images/how-it-works-three.svg',
      title: 'Earn $150 for each approved video',
      content:
        <UitkText size={400}>After submitting, use your affiliate account to track the video review process, see your earnings, and upload more inspiring videos for travelers!</UitkText>
    },
  ];

  const props = {
    carouselName: 'Carouseledgetoedge',
    carouselHeaderText: 'Carousel with edge-to-edge layout via inner spacing',
    useContainerSizing: false,
    itemsMaxHeight: true,
    innerSpacing: {
      sm: 48,
      md: 32,
      lg: 24,
    },
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3
    },
    peek: true,
    peekAmount: {
      sm: 64,
      md: 48,
      lg: 24
    },
    itemsGap: {
      sm: 24,
      md: 12,
      lg: 12
    },
    pagingInset: true,
    wrapItems: true,
    navigationIconId: {
      next: 'next-button',
      prev: 'prev-button'
    },
    buttonText: {
        nextButton: 'Show next card',
        prevButton: 'Show previous card',
    }
  };

  return (
    <div>
      <UitkCarousel {...props} className="hide-desktop">
        {benefits.map((item) => (
          <UitkLayoutFlex direction="column" alignItems="center" justifyContent="start" space="six" key={useId()}>
            <img className={'cc-three-tile__small-grid-svg'} src={item.icon} />
            <UitkLayoutFlex direction="column" alignItems="center" justifyContent="center" space="one">
              <UitkHeading size={5} align="center">{item.title}</UitkHeading>
              <div>
                {item.content}
              </div>
            </UitkLayoutFlex>
          </UitkLayoutFlex>
        ))}
      </UitkCarousel>
      <UitkLayoutGrid columns={3} space="twelve" className="show-desktop">
        {benefits.map((item) => (
          <div className="CVThreeTile__grid-item" key={useId()}>
            <img className={'cc-three-tile__small-grid-svg'} src={item.icon} />
            <UitkHeading size={5}>{item.title}</UitkHeading>
            <div>
              {item.content}
            </div>
          </div>
        ))}
      </UitkLayoutGrid>
    </div>
  )
}
