// 3rd party imports
import * as React from 'react';

// components
import { HeaderLite } from './header-lite';

interface LayoutLiteProps {
  children: React.ReactNode;
  centered?: boolean;
}

export const LayoutLite: React.FunctionComponent<LayoutLiteProps> = ({ children, centered }) => {
  return (
    <div className="Layout">
      <HeaderLite />
      <section className={`Layout__body ${centered ? 'Layout__body--centered' : ''}`}>{children}</section>
    </div>
  );
};
