const facebookLeadEvent = (additionalData) => {
  // @ts-ignore facebook pixel event
  window.fbq('track', 'Lead', ...additionalData);
};

const facebookSubmitApplicationEvent = (additionalData) => {
  // @ts-ignore facebook pixel event
  window.fbq('track', 'SubmitApplication', ...additionalData);
};

const facebookAdvancedMatching = (additionalData) => {
  // @ts-ignore facebook pixel event
  window.fbq('init', '383560014651304', ...additionalData);
};

const facebookPageViewEvent = () => {
  // @ts-ignore facebook pixel event
  window.fbq('track', 'PageView');
};

export { facebookLeadEvent, facebookPageViewEvent, facebookSubmitApplicationEvent, facebookAdvancedMatching };
