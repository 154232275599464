// 3rd party imports
import * as React from 'react';

//Uitk
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkText, UitkHeading } from 'uitk-react-text';
import { UitkExpandoList, UitkExpandoListItem } from 'uitk-react-expando';

import { EventType, useCommonClickStreamEvent } from 'src/util';

export interface FaqProps {
  faqList: any;
  setFaqList: (state) => void;
  innerRef?: any;
}

export function Faq({ faqList, setFaqList, innerRef }: FaqProps): JSX.Element {
  const { trackEvent } = useCommonClickStreamEvent();
  return (
    <div className="CVContainer" ref={innerRef}>
      <UitkLayoutFlex direction="column" alignItems="center" space="twelve" className="CVFaq">
        <UitkLayoutFlex direction="column" alignItems="center" space="six">
          <UitkText size={400} className="cc-text-neutral-1-500">
            FAQ
          </UitkText>
          <UitkHeading size={4} align="center">
            Frequently asked questions
          </UitkHeading>
          <UitkText size={500} className="cc-text-neutral-1-600" align="center">
            Everything you need to know
          </UitkText>
        </UitkLayoutFlex>
        <UitkExpandoList className="CVFaq__expando-list">
          {faqList.map((listItem, index) => (
            <div className="CVFaq__expando-list-item" key={`faq-list-item-${index}`}>
              <UitkExpandoListItem
                {...listItem}
                triggerPosition="trailing"
                onToggle={() => {
                  const tempFaqList = [...faqList];
                  tempFaqList.map((obj) => {
                    obj.isVisible = obj.expandoTitle === listItem.expandoTitle;
                  });

                  trackEvent(
                    {
                      event_type: EventType.INTERACTION,
                      event_name: 'creator_video_faq.selected',
                      action_location: 'creator-engine-faq',
                    },
                    {
                      article: {
                        article_name: listItem.expandoTitle,
                      },
                    },
                  );
                  setFaqList(tempFaqList);
                }}
              />
            </div>
          ))}
        </UitkExpandoList>
      </UitkLayoutFlex>
    </div>
  );
}
