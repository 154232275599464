import * as React from 'react';

import { useState } from 'react';

import { UitkFakeInput } from 'uitk-react-input';
import { UitkFigure, UitkImage } from 'uitk-react-images';
import { UitkToolbar } from 'uitk-react-toolbar';
import { UitkPrimaryButton, UitkButtonSize } from 'uitk-react-button';
import { UitkText } from 'uitk-react-text';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';

import { SubmitFormTypeahead, SubmitFormHotelCard } from 'components/creator-video';

import { CVSubmission } from 'models';

import { useStores } from 'hooks/use-stores';

interface SubmitFormUploadDialogProps {
  onClose: () => void;
  previewThumbnail: string;
  previewTitle: string;
  onAttach: (submission: CVSubmission) => void;
}

export function SubmitFormUploadDialog({
  onClose,
  previewThumbnail,
  previewTitle,
  onAttach,
}: SubmitFormUploadDialogProps) {
  const { uiStore } = useStores();

  const bffUrl = uiStore.bffUrl;

  const [step, setStep] = useState(1);

  const [canAttach, setCanAttach] = useState(false);
  const [showHotelCard, setShowHotelCard] = useState(false);

  const [submission, setSubmission] = useState<CVSubmission | null>({
    thumbUrl: '',
    title: '',
    location: '',
    destinationId: 1,
    propertyId: 1,
    propertyUrl: '',
    propertyName: '',
  });

  async function handleSelection(selection: any) {
    console.log(selection);

    const propertyId = selection.data.selected;
    const location = selection.data.regionNames?.secondaryDisplayName;

    setStep(1);
    setShowHotelCard(true);

    const res = await fetch(`${bffUrl}/api/creator-videos/property-info/${propertyId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { url } = await res.json();

    setSubmission({
      thumbUrl: previewThumbnail,
      title: previewTitle,
      location,
      destinationId: selection.data.cityId,
      propertyId: selection.data.selected,
      propertyUrl: url,
      propertyName: selection.term,
    });

    setCanAttach(true);
  }

  return (
    <div className="CVSheet">
      <div className="CVSheet__wrapper">
        {step === 1 && (
          <div className="CVSheet__container">
            <UitkToolbar
              className="CVSheet__toolbar"
              navigationContent={{
                onClick: () => onClose(),
                navIconLabel: 'Close Toolbar',
              }}
              toolbarTitle="Upload a video"
            />
            <UitkLayoutFlex direction="column" space="six">
              <UitkLayoutFlex alignItems="center" direction="column" space="two">
                <div>
                  <UitkFigure className="CVSheet__thumb">
                    <UitkImage placeholderImage={true} alt="alternative text" src={previewThumbnail} />
                  </UitkFigure>
                </div>
                <UitkText size={200} align="center">
                  {previewTitle}
                </UitkText>
              </UitkLayoutFlex>
              <UitkLayoutFlex direction="column" space="four">
                <UitkText size={300} weight="medium">
                  Tell us where this video was taken
                </UitkText>
                {showHotelCard ? (
                  <SubmitFormHotelCard
                    isLoading={!canAttach}
                    hotelName={submission.propertyName}
                    location={submission.location}
                    propertyUrl={submission.propertyUrl}
                    onEdit={() => setStep(2)}
                  />
                ) : (
                  <UitkFakeInput
                    label="Property name"
                    //placeholder="Search for a property"
                    onClick={() => setStep(2)}
                  />
                )}
              </UitkLayoutFlex>
            </UitkLayoutFlex>
            <UitkPrimaryButton
              size={UitkButtonSize.LARGE}
              disabled={!canAttach}
              isFullWidth={true}
              onClick={() => onAttach(submission)}
            >
              Attach
            </UitkPrimaryButton>
          </div>
        )}
        {step === 2 && (
          <div className="CVSheet__container">
            <UitkToolbar
              className="CVSheet__toolbar"
              navigationContent={{
                navType: 'back',
                onClick: () => setStep(1),
              }}
              toolbarTitle="Find a property"
            />
            <div className="CVSheet__typeahead">
              <SubmitFormTypeahead onDismiss={() => setStep(1)} onSelection={handleSelection} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
