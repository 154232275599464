// 3rd party
import * as React from 'react';
import { MobXProviderContext } from 'mobx-react';

// Bernie
import { AnalyticsStore, ContextStore, ExperimentStore, PageStore } from 'bernie-plugin-mobx';

// Stores
import { UiStore, UnsubscribeStore } from 'stores';

interface AffiliateHubStores {
  // Core
  analytics: AnalyticsStore; // If we're going to use clickstream, do we need this?
  context: ContextStore;
  experiment: ExperimentStore;
  page: PageStore;

  // App
  uiStore: UiStore;
  unsubscribeStore: UnsubscribeStore;
}

function useStores() {
  return React.useContext(MobXProviderContext) as AffiliateHubStores;
}

export { useStores };
