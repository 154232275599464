// 3rd party imports
import * as React from 'react';

//Uitk
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';

import { Carousel, InspirationDisplayVideo } from 'components/creator-video';
import { useCommonClickStreamEvent, EventType } from 'src/util';
import { LinkWithQuery } from 'components/shared';

export interface InspirationProps {
  innerRef: any;
}
export function Inspiration({ innerRef }: InspirationProps) {
  const { trackEvent } = useCommonClickStreamEvent();
  const [inspirationVideos] = React.useState([
    {
      videoUrl: 'https://mediaim.expedia.com/creator-video/creator-inspiration-1.mp4',
      poster: '/images/poster-1.png',
    },
    {
      videoUrl: 'https://mediaim.expedia.com/creator-video/creator-inspiration-2.mp4',
      poster: '/images/poster-2.png',
    },
    {
      videoUrl: 'https://mediaim.expedia.com/creator-video/creator-inspiration-3.mp4',
      poster: '/images/poster-3.png',
    },
    {
      videoUrl: 'https://mediaim.expedia.com/creator-video/creator-inspiration-4.mp4',
      poster: '/images/poster-4.png',
    },
  ]);

  const handleAnalyticsForSubmissionRedirect = () => {
    trackEvent(
      {
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: 'inspiration-section-button',
      },
      { additional_context: { user_interface: { component_element_text: 'inspiration submit button' } } },
    );
  };

  return (
    <div className="CVContainer" ref={innerRef}>
      <UitkLayoutFlex
        direction="column"
        alignItems="center"
        className="CVSection__padding CVGap-64 CVSection__width"
        id="inspiration"
      >
        <UitkLayoutFlex direction="column" tag="header" className="CVGap-16">
          <UitkText size={400} weight="medium" align="center">
            Inspiration
          </UitkText>
          <UitkHeading size={2} tag="h2" align="center">
            Videos to get you inspired
          </UitkHeading>
        </UitkLayoutFlex>
        <div className="CVInspiration__example-carousel">
          <Carousel className="inspiration">
            {inspirationVideos.map((obj, ind) => (
              <div className="CVCarousel__flex-item" key={`cv-inspiration__carousel-item-${ind}`}>
                <InspirationDisplayVideo {...obj} />
              </div>
            ))}
          </Carousel>
        </div>

        <div className="CVInspiration__example-flex">
          {inspirationVideos.map((obj, ind) => (
            <InspirationDisplayVideo key={`cv-inspiration__flex-item-${ind}`} {...obj} />
          ))}
        </div>
        <LinkWithQuery to="/en-us/creator-video/submit">
          <UitkPrimaryButton size={UitkButtonSize.LARGE} onClick={handleAnalyticsForSubmissionRedirect}>
            Submit videos
          </UitkPrimaryButton>
        </LinkWithQuery>
      </UitkLayoutFlex>
    </div>
  );
}
