// 3rd party imports
import * as React from 'react';
import { useState } from 'react'; //Uitk
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkExpandoList, UitkExpandoListItem } from 'uitk-react-expando';
import { UitkTertiaryButton } from 'uitk-react-button';

import {
  UitkTable,
  UitkTableBody,
  UitkTableCell,
  UitkTableCellBorder,
  UitkTableCellTag,
  UitkTableRow,
} from 'uitk-react-tables';
import { UitkList } from 'uitk-react-list';
import { UitkLink } from 'uitk-react-link';
import { EventType, makeLocaleParam, useCommonClickStreamEvent } from 'src/util';
import { useStores } from 'hooks';

export function Faq(): JSX.Element {
  const { trackEvent } = useCommonClickStreamEvent();
  const handleAffiliateRedirectAnalytics = () => {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_join_affiliate.selected',
      action_location: 'landing-page-faq-link',
    });
  };
  const { context } = useStores();

  const localeParam = makeLocaleParam(context.locale);

  const faqs = [
    {
      isVisible: true,
      expandoTitle: 'What is Expedia Group Affiliate Program? Who can apply?',
      expandoDescription: (
        <UitkLayoutFlex direction="column" space="three">
          <UitkText size={400}>
            The Expedia Group Affiliate Program is designed for individuals and businesses to earn commissions by
            promoting Expedia travel products. It is a similar way to earn real money through your travel content as
            you’ll be paid when someone books through one of your links.{' '}
          </UitkText>
          <UitkText size={400}>
            {`Anyone can apply, and we review all applications within minutes. If you'd like to `}
            <UitkLink inline className="CVInlineLink" onClickCapture={handleAffiliateRedirectAnalytics}>
              <a rel="noreferrer" target="_blank" href="https://console.vap.expedia.com/eg-affiliates/signup">
                become an affiliate
              </a>
            </UitkLink>{' '}
            and earn commissions from selling travel, submit your application today.
          </UitkText>
        </UitkLayoutFlex>
      ),
    },
    {
      isVisible: false,
      expandoTitle: 'What is the Travel Video Program beta?',
      expandoDescription: (
        <UitkText size={400}>
          The Travel Video Program beta is designed to gather feedback and insights on how we can enable new
          monetization opportunities for Expedia affiliates. As a beta participant, you&apos;ll submit travel videos
          aligning with our content guidelines to earn $150 for every video approved.
        </UitkText>
      ),
    },
    {
      isVisible: false,
      expandoTitle: 'How long will the beta program last?',
      expandoDescription: (
        <>
          <UitkText size={400}>
            The duration of the beta program is currently undetermined. We are closely monitoring program performance
            and participant feedback. Please note that beta features can change during this period, and you may
            encounter slower content reviews and performance issues. We’ll be sure to provide updates as the program
            evolves and communicate when the official public release is planned.
          </UitkText>
        </>
      ),
    },
    {
      isVisible: false,
      expandoTitle: 'How do I submit a travel video?',
      expandoDescription: (
        <>
          <UitkText size={400}>
            Join the Expedia Group Affiliate Program to submit a video following the{' '}
            <UitkTertiaryButton tag="a" target="_blank" href="/en-us/travel-video/video-guidelines">
              video content guidelines
            </UitkTertiaryButton>
            .
          </UitkText>
        </>
      ),
    },
    {
      isVisible: false,
      expandoTitle: 'What video specifications do I need to follow?',
      expandoDescription: (
        <>
          <UitkText size={400}>We look for content with the following specs:</UitkText>
          <UitkTable border isRounded className="CVFaq__Table">
            <UitkTableBody>
              <UitkTableRow>
                <UitkTableCell
                  border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}
                  tag={UitkTableCellTag.header}
                >
                  Video Duration
                </UitkTableCell>
                <UitkTableCell border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}>
                  Videos should be 10-30 seconds, with a maximum of 60 seconds.
                </UitkTableCell>
              </UitkTableRow>
              <UitkTableRow>
                <UitkTableCell
                  border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}
                  tag={UitkTableCellTag.header}
                >
                  Format
                </UitkTableCell>
                <UitkTableCell border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}>
                  We can only accept videos in{' '}
                  <UitkText className="CVText__italicize" inline>
                    .mp4
                  </UitkText>{' '}
                  and{' '}
                  <UitkText className="CVText__italicize" inline>
                    .mov
                  </UitkText>{' '}
                  formats.
                </UitkTableCell>
              </UitkTableRow>
              <UitkTableRow>
                <UitkTableCell
                  border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}
                  tag={UitkTableCellTag.header}
                >
                  File Size
                </UitkTableCell>
                <UitkTableCell border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}>
                  File size must not exceed 500 MB.
                </UitkTableCell>
              </UitkTableRow>
              <UitkTableRow>
                <UitkTableCell
                  border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}
                  tag={UitkTableCellTag.header}
                >
                  Resolution
                </UitkTableCell>
                <UitkTableCell border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}>
                  The video resolution should be 1080 x 1920 pixels or higher.
                </UitkTableCell>
              </UitkTableRow>
              <UitkTableRow>
                <UitkTableCell
                  border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}
                  tag={UitkTableCellTag.header}
                >
                  Orientation
                </UitkTableCell>
                <UitkTableCell border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}>
                  All clips must be filmed in portrait mode (9:16).
                </UitkTableCell>
              </UitkTableRow>
              <UitkTableRow>
                <UitkTableCell
                  border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}
                  tag={UitkTableCellTag.header}
                >
                  Audio
                </UitkTableCell>
                <UitkTableCell border={[UitkTableCellBorder.horizontal, UitkTableCellBorder.vertical]}>
                  Refrain from including music, voiceovers, or other sound effects. Expedia Group may remove or replace
                  audio on accepted video submissions.
                </UitkTableCell>
              </UitkTableRow>
            </UitkTableBody>
          </UitkTable>
        </>
      ),
    },
    {
      isVisible: false,
      expandoTitle: 'How long does it take for Expedia to review videos? ',
      expandoDescription: (
        <UitkText size={400}>
          We review submissions as quickly as possible, but the exact timeframe varies based on submission volume, along
          with other factors.
        </UitkText>
      ),
    },
    {
      isVisible: false,
      expandoTitle: 'How will I know if my submission qualifies for earning? ',
      expandoDescription: (
        <UitkText size={400}>
          We’ll send you an email after we complete our review. Be sure to check your junk mail folders so you don’t
          miss important updates. If your video is selected, you’ll receive a confirmation email and then you’ll be able
          to see your{' '}
          <UitkLink inline className="CVInlineLink" onClickCapture={handleAffiliateRedirectAnalytics}>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://help.affiliates.expediagroup.com/hc/en-us/articles/15185581399447-How-am-I-paid"
            >
              payment
            </a>
          </UitkLink>
          .
        </UitkText>
      ),
    },
    {
      isVisible: false,
      expandoTitle: 'What rights does Expedia have to use my video?',
      expandoDescription: (
        <UitkText size={400}>
          If your video is approved, Expedia will own a non-exclusive license to use it across our apps, sites, and
          social channels (Instagram, Facebook, TikTok, and more) as applicable. This means your video belongs to you
          and can be reused across other platforms and brand opportunities. For specific details, see our{' '}
          <UitkLink inline className="CVInlineLink">
            <a rel="noreferrer" target="_blank" href={`https://affiliates.expediagroup.com/lp/b/affhub/affiliate?siteid=2055&locale=${localeParam}`}>
              terms and conditions
            </a>
          </UitkLink>
          .
        </UitkText>
      ),
    },
    {
      isVisible: false,
      expandoTitle: 'How can I ensure my video has the best chance of being selected?',
      expandoDescription: (
        <>
          <UitkText size={400}>
            Following our{' '}
            <UitkTertiaryButton tag="a" target="_blank" href="/en-us/creator-video/video-guidelines">
              content guidelines
            </UitkTertiaryButton>{' '}
            ensures your video has the best chance of being selected. To highlight a few important elements from the
            guidelines:
          </UitkText>
          <UitkList>
            <li key="Faq-list-best-chance-brands">
              <UitkText size={400}>
                Don&apos;t mention or show brands or properties not available on an{' '}
                <UitkLink inline>
                  <a rel="noreferrer" target="_blank" href="https://www.expediagroup.com/travel-with-us/default.aspx">
                    Expedia Group brand
                  </a>
                </UitkLink>
                .
              </UitkText>
            </li>
            <li key="Faq-list-best-chance-price">
              <UitkText size={400}>
                Don&apos;t mention prices, promotional information, discount claims, or time-sensitive information.
              </UitkText>
            </li>
            <li key="Faq-list-best-chance-cta">
              <UitkText size={400}>
                Don&apos;t make any calls to action that cannot apply across all social media sites (e.g. “Link in
                bio!”).
              </UitkText>
            </li>
            <li key="Faq-list-best-chance-text">
              <UitkText size={400}>
                Make sure your videos don&apos;t include text, graphics, watermarks, logos, or emojis.{' '}
              </UitkText>
            </li>
            <li key="Faq-list-best-chance-pov">
              <UitkText size={400}>
                Consider 1st and 3rd POV when capturing content. Refrain from including self and other identifiable people in the video. Refrain from crowds and selfies, but anonymous people are okay, like capturing individuals or crowds from behind.
              </UitkText>
            </li>
          </UitkList>
        </>
      ),
    },
  ];

  const [list, setList] = useState(faqs);

  return (
    <div className="CVContainer">
      <UitkLayoutFlex direction="column" alignItems="center" space="twelve" className="CVFaq">
        <UitkLayoutFlex direction="column" alignItems="center" space="six">
          <UitkText size={400} className="cc-text-neutral-1-500">
            FAQ
          </UitkText>
          <UitkHeading size={4} align="center">
            Frequently asked questions
          </UitkHeading>
          <UitkText size={500} className="cc-text-neutral-1-600" align="center">
            Everything you need to know
          </UitkText>
        </UitkLayoutFlex>
        <UitkExpandoList className="CVFaq__expando-list">
          {list.map((listItem, index) => (
            <div className="CVFaq__expando-list-item" key={`faq-list-item-${index}`}>
              <UitkExpandoListItem
                {...listItem}
                onToggle={() => {
                  listItem.isVisible = !listItem.isVisible;
                  trackEvent(
                    {
                      event_type: EventType.INTERACTION,
                      event_name: 'creator_video_faq.selected',
                      action_location: 'travel-video-faq',
                    },
                    {
                      article: {
                        article_name: listItem.expandoTitle,
                      },
                    },
                  );
                  setList([...list]);
                }}
                triggerPosition="trailing"
              />
            </div>
          ))}
        </UitkExpandoList>
      </UitkLayoutFlex>
    </div>
  );
}
