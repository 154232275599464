// 3rd party imports
import * as React from 'react';

//Uitk
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutGrid } from 'uitk-react-layout-grid';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkPrimaryButton } from 'uitk-react-button';
import { useCommonClickStreamEvent, EventType } from 'src/util';
import { LinkWithQuery } from 'components/shared';

export interface ThreeTileProps {
  heading: string;
  subHeading: string;
  gridContent: any;
  isBenefit?: boolean;
  innerRef: any;
}
export function ThreeTile({
  heading,
  subHeading,
  gridContent,
  isBenefit = false,
  innerRef,
}: ThreeTileProps): JSX.Element {
  const { trackEvent } = useCommonClickStreamEvent();
  function desktopLayout() {
    return (
      <UitkLayoutGrid className={`CV${isBenefit ? 'Benefits' : 'HowItWorks'}__grid`} columns={3} rows={1}>
        {gridContent.map((gridItem, ind) => (
          <div className="CVThreeTile__grid-item" key={`cc-${isBenefit ? 'benefits' : 'how-it-works'}-griditem-${ind}`}>
            <img
              className={isBenefit ? 'cc-three-tile__large-grid-svg' : 'cc-three-tile__small-grid-svg'}
              src={gridItem.svg}
            />
            <UitkHeading size={5}>{gridItem.header}</UitkHeading>
            <UitkText className="cc-text-neutral-1-600" size={400}>
              {gridItem.paragraph}
            </UitkText>
          </div>
        ))}
      </UitkLayoutGrid>
    );
  }

  const handleRedirectAnalytics = () => {
    trackEvent(
      {
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: isBenefit ? 'benefits-section-button' : 'how-it-works-section-button',
      },
      {
        additional_context: {
          user_interface: {
            component_element_text: isBenefit ? 'benefits section button' : 'how it works section button',
          },
        },
      },
    );
  };

  return (
    <div className="CVContainer" ref={innerRef}>
      <UitkLayoutFlex
        direction="column"
        alignItems="center"
        alignContent="center"
        space="twelve"
        id={isBenefit ? 'benefits' : 'how-it-works'}
      >
        <UitkLayoutFlex direction="column" className="CVGap-16" tag="header">
          <UitkText className="cc-text-neutral-1-600" size={400} weight="medium" align="center">
            {subHeading}
          </UitkText>
          <UitkHeading size={3} tag="h2" align="center">
            {heading}
          </UitkHeading>
        </UitkLayoutFlex>
        <UitkLayoutFlexItem alignSelf="center">{desktopLayout()}</UitkLayoutFlexItem>

        <LinkWithQuery to="/en-us/creator-video/submit">
          <UitkPrimaryButton className="cc-three-tile__button" onClick={handleRedirectAnalytics}>
            {isBenefit ? "I'm ready" : 'Upload my content'}
          </UitkPrimaryButton>
        </LinkWithQuery>
      </UitkLayoutFlex>
    </div>
  );
}
