import React, { useId } from 'react';

import { UitkText } from 'uitk-react-text';
import { UitkCarousel } from 'uitk-react-carousel';
import { UitkHeading } from 'uitk-react-text';
import { UitkLayoutGrid } from 'uitk-react-layout-grid';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';

export function Tips() {

  const benefits = [
    {
      icon: '/images/travel-video/devices.svg',
      title: 'Think ‘mobile-friendly’',
      content:
        <UitkText size={400}>Videos filmed in a portrait orientation (9:16) are perfect for TikTok, Instagram Reels, and YouTube Shorts. Additionally, keep in mind short attention spans. Hook viewers within the first 5 seconds.</UitkText>,
    },
    {
      icon: '/images/travel-video/snip.svg',
      title: 'Keep it short and sweet',
      content:
        <UitkText size={400}>We&apos;ve seen that 30 seconds is the sweet spot for our best-performing content. If you have lots of content on a property, make a separate video!</UitkText>,
    },
    {
      icon: '/images/travel-video/privacy.svg',
      title: 'Be mindful of privacy',
      content:
        <UitkText size={400}>Avoid including identifiable people and minors to protect privacy and ensure your video meets our content guidelines.</UitkText>,
    },
    {
      icon: '/images/travel-video/details.svg',
      title: 'It’s all in the details',
      content:
        <UitkText size={400}>Show off the property by spotlighting 2-3 key areas in one video. Capture the exterior, stunning views, and inviting interiors, plus amenities like pools, dining, and the special touches that stand out.</UitkText>,
    },
    {
      icon: '/images/travel-video/keys.svg',
      title: 'Authenticity is key',
      content:
        <UitkText size={400}>Today, viewers are savvy as to if you’re speaking genuinely. Ensure your content is your own and don’t be afraid to show your personality.</UitkText>,
    },
    {
      icon: '/images/travel-video/less.svg',
      title: 'Less is more',
      content:
        <UitkText size={400}>Keep the your video presentation clean by skipping fonts, stickers, and emojis so your content can shine without unnecessary distractions.</UitkText>,
    },
  ];

  const props = {
    carouselName: 'Carouseledgetoedge',
    carouselHeaderText: 'Carousel with edge-to-edge layout via inner spacing',
    useContainerSizing: false,
    itemsMaxHeight: true,
    innerSpacing: {
      sm: 48,
      md: 32,
      lg: 24,
    },
    itemsVisible: {
      sm: 1,
      md: 2,
      lg: 3
    },
    peek: true,
    peekAmount: {
      sm: 64,
      md: 48,
      lg: 24
    },
    itemsGap: {
      sm: 24,
      md: 12,
      lg: 12
    },
    pagingInset: true,
    wrapItems: true,
    navigationIconId: {
      next: 'next-button',
      prev: 'prev-button'
    },
    buttonText: {
        nextButton: 'Show next card',
        prevButton: 'Show previous card',
    }
  };

  return (
    <div>
      <UitkCarousel {...props} className="hide-desktop">
        {benefits.map((item) => (
          <UitkLayoutFlex direction="column" alignItems="start" space="six" key={useId()}>
            <img src={item.icon} />
            <UitkLayoutFlex direction="column" space="two">
              <UitkHeading size={5}>{item.title}</UitkHeading>
              <div>
                {item.content}
              </div>
            </UitkLayoutFlex>
          </UitkLayoutFlex>
        ))}
      </UitkCarousel>
      <UitkLayoutGrid columns={3} space="twelve" className="show-desktop">
        {benefits.map((item) => (
          <UitkLayoutFlex direction="column" alignItems="start" space="six" key={useId()}>
            <img src={item.icon} />
            <UitkLayoutFlex direction="column" space="three">
              <UitkHeading size={5}>{item.title}</UitkHeading>
              <div>
                {item.content}
              </div>
            </UitkLayoutFlex>
          </UitkLayoutFlex>
        ))}
      </UitkLayoutGrid>
    </div>
  )
}
