// 3rd party imports
import * as React from 'react';

//Uitk
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { LinkWithQuery } from 'components/shared/link-with-query';

import { useCommonClickStreamEvent, EventType } from 'src/util';

export interface TipsProps {
  innerRef?: any;
  isMobile?: boolean;
}
export function Tips({ innerRef }: TipsProps) {
  const { trackEvent } = useCommonClickStreamEvent();
  const tipGridItems = [
    {
      svg: '/images/mobile-friendly.svg',
      titleText: 'Think ‘mobile-friendly’',
      paragraphText:
        'Videos filmed in a portrait orientation (9:16) are perfect for TikTok, Instagram Reels and YouTube Shorts. Additionally, bear in mind short attention spans. Hook viewers within the first 5 seconds.',
    },
    {
      svg: '/images/keep-it-short.svg',
      titleText: 'Keep it short and sweet',
      paragraphText:
        "We've seen that 30 seconds is the sweet spot for our best-performing content. If you have lots of content on a property, make a separate video!",
    },
    {
      svg: '/images/grab-attention.svg',
      titleText: 'Grab attention immediately',
      paragraphText:
        'Understand that internet users are more than happy to swipe and scroll to the next post if they don’t find the content in front of them interesting. To hook viewers to your video, start with an attention-grabbing visual.',
    },
    {
      svg: '/images/its-all-in-details.svg',
      titleText: 'It’s all in the details',
      paragraphText:
        'Discovered a hidden gem? Or received accommodating service when travelling with your family? Speak to it! A unique perspective keeps viewers engaged.',
    },
    {
      svg: '/images/authenticity-is-key.svg',
      titleText: 'Authenticity is key',
      paragraphText:
        'Today, viewers are savvy as to whether you’re speaking genuinely. Ensure your content is your own and don’t be afraid to show your personality.',
    },
    {
      svg: '/images/dont-reinvent-wheel.svg',
      titleText: 'No need to reinvent the wheel',
      paragraphText:
        'You may already have content that your viewers, friends and family love while fitting within the requirements. Start submitting these first before you start creating from scratch.',
    },
  ];

  return (
    <div className="CVContainer" ref={innerRef}>
      <UitkLayoutFlex
        direction="column"
        alignItems="center"
        alignContent="center"
        className="CVSection__padding  CVGap-64 CVSection__width"
        id="tips"
      >
        <UitkLayoutFlex direction="column" className="CVGap-16" tag="header">
          <UitkText className="cc-text-neutral-1-600" size={400} weight="medium" align="center">
            Tips
          </UitkText>
          <UitkHeading size={2} tag="h2" align="center">
            Content winning tips
          </UitkHeading>
        </UitkLayoutFlex>
        <UitkLayoutFlexItem alignSelf="center">
          <UitkLayoutGrid className="CVTips__grid" columns={3} space={{ column: 'six' }}>
            {tipGridItems.map((obj, ind) => (
              <UitkLayoutGridItem key={`cc-tips__${ind}`}>
                <div className="CVTips__item-flex">
                  <img className="CVTips__svg" src={obj.svg} />
                  <UitkLayoutFlex direction="column" space="three">
                    <UitkHeading className="CVTips__benefit-heading" size={5}>
                      {obj.titleText}
                    </UitkHeading>
                    <UitkText size={400}>{obj.paragraphText}</UitkText>
                  </UitkLayoutFlex>
                </div>
              </UitkLayoutGridItem>
            ))}
          </UitkLayoutGrid>
        </UitkLayoutFlexItem>
        <LinkWithQuery
          to="/en-us/creator-video/submit"
          onClick={() => trackEvent({
              event_type: EventType.INTERACTION,
              event_name: 'creator_video_go_to_submission.selected',
              action_location: 'tips-section-button',
            },
            {
              additional_context: {
                user_interface: {
                  component_element_text: 'tips-section-button',
                },
              },
            },
            )
          }
        >
          <UitkPrimaryButton size={UitkButtonSize.LARGE} className="CVTips__button">
            Submit videos
          </UitkPrimaryButton>
        </LinkWithQuery>
      </UitkLayoutFlex>
    </div>
  );
}
