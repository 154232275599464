// 3rd party imports
import * as React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

// bernie
import { LocalizedComponentProps, LocalizedText, useLocalization } from '@shared-ui/localization-context';

// ui
import { UitkLayoutGrid, UitkLayoutGridItem } from 'uitk-react-layout-grid';
import { UitkSpacing, UitkSpacingHr } from 'uitk-react-spacing';
import { UitkLink } from 'uitk-react-link';
import { UitkText, UitkHeading } from 'uitk-react-text';
import { makeLocaleParam } from 'src/util';
import { useStores } from 'src/hooks';
import { DirectWord } from 'shared/direct-word';

// util
export interface ExpandFooterProps extends LocalizedComponentProps, RouteComponentProps {
  cookieLinkRef?: React.RefObject<undefined>;
}

const ExpandFooterComponent: React.FunctionComponent<ExpandFooterProps> = ({ cookieLinkRef }) => {
  const l10n = useLocalization();
  const { context, uiStore } = useStores();

  const { pageName } = uiStore;
  const locale = makeLocaleParam(context.locale);
  const logoLink = `/${locale}/home`;
  const currentYear = new Date().getFullYear();

  return (
    <UitkSpacing padding={{ block: 'twelve' }} border={['blockstart']} className="ExpandFooter light-section-bg">
      <footer>
        <div className="container">
          <UitkLink>
            <Link to={logoLink}>
              <img
                className="Header__logo"
                src="/images/logos/eg-affiliate-program-logo-blue.svg"
                alt="Expedia Group Affiliate Program"
              />
            </Link>
          </UitkLink>
          <UitkSpacing margin={{ block: 'twelve' }}>
            <UitkLayoutGrid columns={['1fr', '1fr']} space="four">
              <UitkLayoutGridItem>
                <UitkHeading size={6}>
                  <LocalizedText message="footer.heading.support" />
                </UitkHeading>
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <UitkHeading size={6}>
                  <LocalizedText message="footer.heading.policies" />
                </UitkHeading>
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <UitkLink>
                  <a href="https://help.affiliates.expediagroup.com/hc/en-us" target="_blank" rel="noreferrer">
                    <LocalizedText message="footer.links.help_center" />
                  </a>
                </UitkLink>
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <UitkLink>
                  <Link to={`/${locale}/doc/privacy-policy`} target="_blank">
                    <LocalizedText message="footer.links.privacy_policy" />
                  </Link>
                </UitkLink>
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <UitkLink>
                  <a
                    href="https://help.affiliates.expediagroup.com/hc/en-us/categories/13657662298775"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <LocalizedText message="footer.links.resources" />
                  </a>
                </UitkLink>
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <UitkLink>
                  <Link to={`/${locale}/doc/terms-of-use`} target="_blank">
                    <LocalizedText message="footer.links.terms_of_use" />
                  </Link>
                </UitkLink>
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <UitkLink>
                  <a href="mailto:expedia.support@partnerize.com" target="_blank" rel="noreferrer">
                    <LocalizedText message="footer.links.contact_us" />
                  </a>
                </UitkLink>
              </UitkLayoutGridItem>
              <UitkLayoutGridItem>
                <UitkLink>
                  <Link to={`/${locale}/doc/cookie-policy`} target="_blank" ref={cookieLinkRef}>
                    <LocalizedText message="footer.links.cookie_policy" />
                  </Link>
                </UitkLink>
              </UitkLayoutGridItem>
            </UitkLayoutGrid>
          </UitkSpacing>
          <div className="Footer__hr">
            <UitkSpacingHr blockMargin={'six'} />
          </div>
          <UitkText size={200}>{l10n.formatText('footer.content.all_rights_reserved', currentYear)}</UitkText>
          <div className="Footer__feedback">
            <DirectWord pageName={pageName} showIcon={true} display="floating" locale={locale} l10n={l10n} />
          </div>
        </div>
      </footer>
    </UitkSpacing>
  );
};

export const ExpandFooter = withRouter(ExpandFooterComponent);
