export const baseEventFragmentPayload = {
  event_version: '1.0.0',
  event_type: 'Interaction',
  event_category: 'landing',
};

export const creatorVideoBaseEventFragmentPayload = {
  event_version: '2.0.0',
  event_type: 'Interaction',
  event_category: 'landing',
};

export const baseExperienceFragmentPayload = {
  page_name: 'affiliate-hub',
  app_name: 'affiliate-hub-pwa',
  app_version: '1.0.0',
};
