export function validateVideo(file: File, videoElement: HTMLVideoElement): Promise<string[]> {
  return new Promise((resolve) => {
    const errors: string[] = [];

    if (file.size > 500 * 1024 * 1024) {
      errors.push('The file size must be less than 500MB.');
    }

    videoElement.addEventListener(
      'loadedmetadata',
      () => {
        if (videoElement.videoWidth < 1080 || videoElement.videoHeight < 1920) {
          errors.push('The video resolution must be at least 1080*1920.');
        }

        const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
        const expectedAspectRatio = 9 / 16;
        const tolerance = 0.01;
        if (Math.abs(aspectRatio - expectedAspectRatio) > tolerance) {
          errors.push('The video must be in a 9:16 aspect ratio.');
        }

        if (videoElement.duration <= 5.9 || videoElement.duration >= 60) {
          errors.push('The video must be between 6 - 60 seconds.');
        }

        resolve(errors);
      },
      { once: true },
    );
  });
}
