// 3rd party imports
import * as React from 'react';

//Uitk
import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkParagraph, UitkText } from 'uitk-react-text';
import { UitkPrimaryButton, UitkButtonSize } from 'uitk-react-button';

import { useCommonClickStreamEvent, EventType } from 'src/util';

export function AffiliateCard() {
  const { trackEvent } = useCommonClickStreamEvent();

  const handleRedirect = () => {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_join_affiliate.selected',
      action_location: 'affiliate-card-join-today-button',
    });
  };

  return (
    <div className="CVContainer">
      <UitkCard className="CVAffiliates__card CVSection__width">
        <UitkLayoutFlex className="CVAffiliates__card-wrapper" justifyContent="space-between" alignItems="center">
          <UitkLayoutFlexItem className="CVAffiliates__content">
            <UitkLayoutFlex justifyContent="center" className="cc-affiliate__image">
              <UitkLayoutFlexItem alignSelf="end">
                <img alt="splatter" className="CVAffiliate__image_splatter" src="/images/splatter.svg" />
              </UitkLayoutFlexItem>
              <UitkLayoutFlexItem className="CVAffiliate__image-wrap">
                <img alt="affiliate dashboard preview" className="CVAffiliate__image" src="/images/Dashboard.png" />
              </UitkLayoutFlexItem>
            </UitkLayoutFlex>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem>
            <UitkCardContentSection padded={false} className="cc-affiliate-card__text-content">
              <UitkLayoutFlex direction="column">
                <UitkText weight="bold" size={500} className="cc-text-neutral-1-700  CVAffiliate_heading">
                  Join the Expedia Group Affiliate Program
                </UitkText>
                <UitkParagraph
                  className="CVAffiliates__paragraph-size cc-text-neutral-1-500 ${
                    CVAffiliate_heading"
                >
                  Calling all creators! Join the Expedia Group Affiliate Program today to monetize your content online
                  and grow your audience.
                </UitkParagraph>
              </UitkLayoutFlex>
            </UitkCardContentSection>
          </UitkLayoutFlexItem>
          <UitkLayoutFlexItem>
            <UitkCardContentSection>
              <UitkPrimaryButton
                onClick={handleRedirect}
                size={UitkButtonSize.LARGE}
                className="cc-affiliate-card__button"
                href="http://console.vap.expedia.com/eg-affiliates"
                target="_blank"
                tag="a"
              >
                Join today
              </UitkPrimaryButton>
            </UitkCardContentSection>
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkCard>
    </div>
  );
}
