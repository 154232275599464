import React, { useId } from 'react';

import { UitkSpacing } from 'uitk-react-spacing';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLink } from 'uitk-react-link';
import { UitkLayoutGrid } from 'uitk-react-layout-grid';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { EventType, useCommonClickStreamEvent } from 'src/util';

export function TravelVideoBenefits() {
  const { trackEvent } = useCommonClickStreamEvent();
  const handleAffiliateRedirectAnalytics = () => {
    trackEvent({
      event_type: EventType.INTERACTION,
      event_name: 'creator_video_join_affiliate.selected',
      action_location: 'landing-page-benefits-link',
    });
  };

  const benefits = [
    {
      icon: '/images/travel-video/featured.svg',
      title: 'Earn for every featured video',
      content: (
        <UitkText size={400} align="center">
          Each video we use earns you $150. There&apos;s no limit on submissions, so keep uploading for a chance to fund
          your next trip!
        </UitkText>
      ),
    },
    {
      icon: '/images/travel-video/goggles.svg',
      title: 'See your content come to life',
      content: (
        <UitkText size={400} align="center">
          We&apos;ll share your content across our sites and social pages, where millions of travelers will be inspired
          by what you&apos;ve created
        </UitkText>
      ),
    },
    {
      icon: '/images/travel-video/chart.svg',
      title: 'Unlock more earning opportunities',
      content: (
        <UitkText size={400} align="center">
          Leverage creator tools within our{' '}
          <UitkLink inline onClick={handleAffiliateRedirectAnalytics}>
            <a href="https://console.vap.expedia.com/eg-affiliates/signup">affiliate program</a>
          </UitkLink>{' '}
          to earn even more with your content. Not an affiliate yet? Join the program today!
        </UitkText>
      ),
    },
  ];

  return (
    <div>
      <UitkLayoutGrid columns={{ small: 1, large: 3 }} space="twelve">
        {benefits.map((item) => (
          <UitkSpacing padding={{ inline: 'six' }} key={useId()}>
            <UitkLayoutFlex direction="column" alignItems="center" justifyContent="start" space="six" key={useId()}>
              <img className={'cc-three-tile__small-grid-svg'} src={item.icon} />
              <UitkLayoutFlex direction="column" alignItems="center" justifyContent="center" space="three">
                <UitkHeading size={5} align="center">
                  {item.title}
                </UitkHeading>
                {item.content}
              </UitkLayoutFlex>
            </UitkLayoutFlex>
          </UitkSpacing>
        ))}
      </UitkLayoutGrid>
    </div>
  );
}
