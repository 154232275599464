// 3rd party imports
import * as React from 'react';

//Uitk
import { UitkButtonSize, UitkPrimaryButton } from 'uitk-react-button';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { Carousel } from 'components/creator-video';
import { useCommonClickStreamEvent, EventType } from 'src/util';
import { LinkWithQuery } from 'components/shared/link-with-query';

export interface TipsMobileProps {
  innerRef?: any;
}

export function TipsMobile({ innerRef }: TipsMobileProps) {
  const { trackEvent } = useCommonClickStreamEvent();
  const tipGridItems = [
    {
      svg: '/images/mobile-friendly.svg',
      titleText: 'Think ‘mobile-friendly’',
      paragraphText:
        'Videos filmed in a portrait orientation (9:16) are perfect for TikTok, Instagram Reels and YouTube Shorts. Additionally, bear in mind short attention spans. Hook viewers within the first 5 seconds.',
    },
    {
      svg: '/images/keep-it-short.svg',
      titleText: 'Keep it short and sweet',
      paragraphText:
        "We've seen that 30 seconds is the sweet spot for our best-performing content. If you have lots of content on a property, make a separate video!",
    },
    {
      svg: '/images/grab-attention.svg',
      titleText: 'Grab attention immediately',
      paragraphText:
        'Understand that internet users are more than happy to swipe and scroll to the next post if they don’t find the content in front of them interesting. To hook viewers to your video, start with an attention-grabbing visual.',
    },
    {
      svg: '/images/its-all-in-details.svg',
      titleText: 'It’s all in the details',
      paragraphText:
        'Discovered a hidden gem? Or received accommodating service when travelling with your family? Speak to it! A unique perspective keeps viewers engaged.',
    },
    {
      svg: '/images/authenticity-is-key.svg',
      titleText: 'Authenticity is key',
      paragraphText:
        'Today, viewers are savvy as to whether you’re speaking genuinely. Ensure your content is your own and don’t be afraid to show your personality.',
    },
    {
      svg: '/images/dont-reinvent-wheel.svg',
      titleText: 'No need to reinvent the wheel',
      paragraphText:
        'You may already have content that your viewers, friends and family love while fitting within the requirements. Start submitting these first before you start creating from scratch.',
    },
  ];

  const handleAnchorClick = ({ event, anchor }) => {
    event.preventDefault();
    let currentUrl = window.location.href;
    currentUrl = currentUrl.split('#')[0];
    const anchorText = anchor.replace('#', '');
    trackEvent(
      {
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_go_to_submission.selected',
        action_location: 'tips-section-button',
      },
      {
        additional_context: {
          user_interface: {
            component_element_text: anchorText,
          },
        },
      },
    );
    if (currentUrl.includes('creator-video/submit')) {
      location.hash = anchor;
      window.dispatchEvent(new HashChangeEvent('hashchange')); // manually dispatch hashchange event
    } else {
      window.location.href = `/en-us/creator-video/submit` + window.location.search;
    }
  };

  return (
    <div className="CVContainer" ref={innerRef}>
      <UitkLayoutFlex
        direction="column"
        alignItems="center"
        alignContent="center"
        className="CVSection__padding  CVGap-64 CVSection__width"
        id="tips"
      >
        <UitkLayoutFlex direction="column" className="CVGap-16" tag="header">
          <UitkText className="cc-text-neutral-1-600" size={400} weight="medium" align="center">
            Tips
          </UitkText>
          <UitkHeading size={4} tag="h2" align="center">
            Content winning tips
          </UitkHeading>
        </UitkLayoutFlex>

        <div className="CVTips__carousel">
          <Carousel className="text">
            {tipGridItems.map((obj, ind) => (
              <div className="CVCarousel__flex-item" key={`cc-tips__${ind}`}>
                <div className="CVTips__item-flex">
                  <img className="CVTips__svg" src={obj.svg} />
                  <UitkLayoutFlex direction="column" space="three">
                    <UitkHeading className="CVTips__benefit-heading" size={5}>
                      {obj.titleText}
                    </UitkHeading>
                    <UitkText size={400}>{obj.paragraphText}</UitkText>
                  </UitkLayoutFlex>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <LinkWithQuery
          to="/en-us/creator-video/submit"
          onClick={(event) => handleAnchorClick({ event, anchor: '#submit' })}
        >
          <UitkPrimaryButton size={UitkButtonSize.LARGE} className="CVTips__button">
            Submit videos
          </UitkPrimaryButton>
        </LinkWithQuery>
      </UitkLayoutFlex>
    </div>
  );
}
