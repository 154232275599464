import * as React from 'react';

import { useState, useEffect } from 'react';

import { UitkFakeInput, UitkInput } from 'uitk-react-input';
import { UitkToolbar } from 'uitk-react-toolbar';
import { UitkPrimaryButton, UitkButtonSize } from 'uitk-react-button';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkSpacing } from 'uitk-react-spacing';

import { SubmitFormTypeahead, SubmitFormHotelCard } from 'components/creator-video';

import { CVSubmission } from 'models';

import { useStores } from 'hooks/use-stores';
import { useCommonClickStreamEvent, EventType } from 'src/util';

export function SubmitFormLinkDialog({
  onClose,
  onAttach,
}: {
  onClose: () => void;
  onAttach: (submission: CVSubmission) => void;
}) {
  const { uiStore } = useStores();
  const { trackEvent } = useCommonClickStreamEvent();

  const bffUrl = uiStore.bffUrl;

  const [step, setStep] = useState(1);
  const [debounceTimeoutId, setDebounceTimeoutId] = useState(null);

  const [canAttach, setCanAttach] = useState(false);
  const [showHotelCard, setShowHotelCard] = useState(false);

  const [socialVideoUrl, setSocialVideoUrl] = useState('');
  const [socialPlatform, setSocialPlatform] = useState('');
  const [errors, setErrors] = useState({ socialVideoUrl: '' });

  const [submission, setSubmission] = useState<CVSubmission | null>({
    thumbUrl: '',
    title: '',
    location: '',
    destinationId: 1,
    propertyId: 1,
    propertyUrl: '',
    propertyName: '',
  });

  useEffect(() => {
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (!socialVideoUrl) {
        setErrors({ ...errors, socialVideoUrl: '' });
      } else if (socialVideoUrl.includes('instagram')) {
        setErrors({ ...errors, socialVideoUrl: '' });
        setSocialPlatform('instagram');
      } else if (socialVideoUrl.includes('facebook')) {
        setErrors({ ...errors, socialVideoUrl: '' });
        setSocialPlatform('facebook');
      } else if (socialVideoUrl.includes('tiktok')) {
        setErrors({ ...errors, socialVideoUrl: '' });
        setSocialPlatform('tiktok');
      } else {
        setErrors({ ...errors, socialVideoUrl: 'Invalid social platform' });
      }
    }, 250);

    // Save the timeout ID so it can be cleared if the user types again
    setDebounceTimeoutId(newTimeoutId);

    return () => {
      clearTimeout(newTimeoutId);
    };
  }, [socialVideoUrl]);

  async function handleSelection(selection: any) {
    const propertyId = selection.data.selected;
    const location = selection.data.regionNames?.secondaryDisplayName;

    setStep(1);
    setShowHotelCard(true);

    const res = await fetch(`${bffUrl}/api/creator-videos/property-info/${propertyId}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    const { url } = await res.json();

    setSubmission({
      externalVideoUrl: socialVideoUrl,
      socialPlatform,
      location,
      destinationId: selection.data.cityId,
      propertyId: selection.data.selected,
      propertyUrl: url,
      propertyName: selection.term,
    });
    trackEvent(
      {
        event_type: EventType.OUTCOME,
        event_name: 'creator_video_submission.succeeded',
        action_location: 'submission-form',
      },
      {
        form: {
          form_name: 'creator_video_submission',
          short_field_name: 'Attach property and property url to submission',
        },
      },
    );
    setCanAttach(true);
  }

  return (
    <div className="CVSheet">
      <div className="CVSheet__wrapper">
        {step === 1 && (
          <div className="CVSheet__container">
            <UitkToolbar
              className="CVSheet__toolbar CVSheet__toolbar--floating"
              navigationContent={{
                onClick: () => onClose(),
                navIconLabel: 'Close Toolbar',
              }}
            />
            <UitkLayoutFlex direction="column" space="six">
              <UitkSpacing padding={{ blockstart: 'twelve' }}>
                <UitkLayoutFlex direction="column" space="four">
                  <UitkHeading size={5}>Paste a link to your video</UitkHeading>
                  <UitkLayoutFlex direction="column" space="two">
                    <UitkInput
                      label="Link to your video"
                      placeholder="Paste a link to a video"
                      onChange={(e) => setSocialVideoUrl(e.target.value)}
                      value={socialVideoUrl}
                      invalid={errors?.socialVideoUrl && errors?.socialVideoUrl}
                    />
                    <UitkText size={200}>
                      We support links from Instagram, Facebook and TikTok. Also ensure your account is publicly
                      accessible.
                    </UitkText>
                  </UitkLayoutFlex>
                </UitkLayoutFlex>
              </UitkSpacing>
              <UitkLayoutFlex direction="column" space="four">
                <UitkText size={300} weight="medium">
                  Tell us where this video was taken
                </UitkText>
                {showHotelCard ? (
                  <SubmitFormHotelCard
                    isLoading={!canAttach}
                    hotelName={submission.propertyName}
                    location={submission.location}
                    propertyUrl={submission.propertyUrl}
                    onEdit={() => setStep(2)}
                  />
                ) : (
                  <UitkFakeInput
                    label="Property name"
                    //placeholder="Search for a property"
                    onClick={() => setStep(2)}
                  />
                )}
              </UitkLayoutFlex>
            </UitkLayoutFlex>
            <UitkPrimaryButton
              size={UitkButtonSize.LARGE}
              disabled={!canAttach || (!socialVideoUrl || Boolean(errors.socialVideoUrl))}
              isFullWidth={true}
              onClick={() => onAttach(submission)}
            >
              Attach
            </UitkPrimaryButton>
          </div>
        )}
        {step === 2 && (
          <div className="CVSheet__container">
            <UitkToolbar
              className="CVSheet__toolbar"
              navigationContent={{
                navType: 'back',
                onClick: () => setStep(1),
              }}
              toolbarTitle="Find a property"
            />
            <div className="CVSheet__typeahead">
              <SubmitFormTypeahead onDismiss={() => setStep(1)} onSelection={handleSelection} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
