// 3rd party imports
import * as React from 'react';
import { FunctionComponent, useState } from 'react';

// shared-ui
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { LocalizedText } from '@shared-ui/localization-context';

// ui
import { UitkSecondaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon } from 'uitk-react-icons';
import { UitkLayoutFlex, UitkLayoutFlexItem } from 'uitk-react-layout-flex';
import { UitkList, UitkListItem } from 'uitk-react-list';
import { UitkMenu, UitkMenuContainer, UitkMenuTrigger } from 'uitk-react-menu';
import { UitkSpacing } from 'uitk-react-spacing';
import { UitkText } from 'uitk-react-text';

import { useStores } from 'src/hooks';
import { headerSigninSelectedEvent, makeLocaleParam } from 'src/util';
import { JoinNowAction } from '../join-now-action';

const SigninAndJoinNav: FunctionComponent = () => {
  const track = useEgClickstream();
  const { context } = useStores();

  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const signInText = (
    <UitkText size={300}>
      <LocalizedText message="common.sign_in" />
    </UitkText>
  );
  const signInUrl = `/${makeLocaleParam(context.locale)}/signin`;
  const signInLink = (
    <a href={signInUrl} data-cy="header-sign-in-link">
      {signInText}
    </a>
  );

  return (
    <UitkLayoutFlex alignItems="center" className="no-bullet" direction="row" space="three" tag="ul">
      <li className="visible-desktop--large">
        <UitkTertiaryButton onClickCapture={() => headerSigninSelectedEvent(track)}>{signInLink}</UitkTertiaryButton>
      </li>
      <li className="visible-desktop--large">
        <JoinNowAction actionLocation="header" />
      </li>
      <li className="visible-mobile--large">
        <UitkMenu isOpen={menuOpen} onTriggerClick={handleMenuClick}>
          <UitkMenuTrigger>
            <UitkTertiaryButton data-stid="mobile-nav-menu-btn">
              <UitkIcon name="account_circle" className="Header__icon" />
            </UitkTertiaryButton>
          </UitkMenuTrigger>
          <UitkMenuContainer autoPosition className="HeaderJoinNow__menu_item">
            <UitkSpacing padding={{ inline: 'four', blockstart: 'half', blockend: 'two' }}>
              <UitkList bullet="no-bullet" data-stid="mobile-nav-menu">
                <UitkListItem>
                  <UitkLayoutFlex direction="column">
                    <UitkLayoutFlexItem>
                      <JoinNowAction actionLocation="header" />
                    </UitkLayoutFlexItem>
                  </UitkLayoutFlex>
                </UitkListItem>
                <UitkListItem>
                  <UitkSpacing padding={{ inline: 'twelve' }}>
                    <UitkSecondaryButton
                      tag="a"
                      href={signInUrl}
                      isFullWidth={true}
                      data-cy="header-sign-in-link"
                      onClickCapture={() => headerSigninSelectedEvent(track)}
                    >
                      {signInText}
                    </UitkSecondaryButton>
                  </UitkSpacing>
                </UitkListItem>
              </UitkList>
            </UitkSpacing>
          </UitkMenuContainer>
        </UitkMenu>
      </li>
    </UitkLayoutFlex>
  );
};

export { SigninAndJoinNav };
