import * as React from 'react';

import { useRef, useState, useEffect } from 'react';

import { useForm, Controller, useFieldArray } from 'react-hook-form';

import { getVideoThumbnail, validateVideo, useCommonClickStreamEvent, EventType } from 'src/util';

import { UitkButtonSize, UitkPrimaryButton, UitkSecondaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkInput } from 'uitk-react-input';
import { UitkLink } from 'uitk-react-link';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkBadgeStandard } from 'uitk-react-badge';
import { UitkList, UitkListItem } from 'uitk-react-list';
import { UitkBanner } from 'uitk-react-banner';
import { UitkCheckbox } from 'uitk-react-checkbox';

import { SubmitFormUploadDialog, SubmitFormSubmission, SubmitFormLinkDialog, Dialog } from 'components/creator-video';

import { useHistory } from 'react-router-dom';
import { UitkLoader } from 'uitk-react-loader';
import { CVSubmission } from 'models';

import { useStores, useLogger } from 'hooks';
import { useDialog } from '@shared-ui/dialog-context';

import { useDevice, facebookLeadEvent, facebookSubmitApplicationEvent, facebookAdvancedMatching } from 'src/util';

import { useLocation } from 'react-router-dom';

export interface SubmitFormProps {
  innerRef?: React.RefObject<HTMLDivElement>;
}

export enum Source {
  TRIP_INBOX = 'TRIP_INBOX',
  TRIP_ITEM_DETAILS_PAGE = 'TRIP_ITEM_DETAILS_PAGE'
}

export function SubmitForm({ innerRef }: SubmitFormProps) {
  const { isMobile } = useDevice();
  const { uiStore } = useStores();
  const { trackEvent } = useCommonClickStreamEvent();

  const logger = useLogger();

  const bffUrl = uiStore.bffUrl;

  const history = useHistory();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const adSetId = searchParams.get('adSetId');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fileRef = useRef<HTMLInputElement>(null);

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const [isUploadDialogOpen, setIsUploadDialogOpen] = useState<boolean>(false);
  const [isLinkDialogOpen, setIsLinkDialogOpen] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [previewThumbnail, setPreviewThumbnail] = useState<string | null>(null);
  const [previewTitle, setPreviewTitle] = useState<string | null>(null);

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [showUploadError, setShowUploadError] = useState<boolean>(false);

  // this is combined regex for facebook, instagram and tiktok using references from around the web and combining them into one regex
  const profileRegex =
    /(?:(?:www\.)?(?:facebook|fb)\.com\/(?:(?![A-Za-z0-9_]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)(\w+)+\/?|profile\.php\?id=[0-9]+)|(?:www\.)?(?:instagram.com|instagr.am|instagr.com)\/(\w+)(?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?|(?:www\.)?tiktok\.com\/@[^/]+\/?(?![^\s]))/i;

  const desktopVideoContentGuidelinesTrigger = React.useRef(null);
  const fullSheetDialogTrigger = React.useRef(null);
  const [isVCG, setIsVCG] = React.useState(false);

  const [isVCGDialogOpen, dialogVCGActions, DialogVCGComponent] = useDialog('VCG-necessary-dialog-layer-submit-form');

  const [isFullDialogOpen, dialogFullActions, DialogFullComponent] = useDialog(
    'CV-footer-full-necessary-dialog-layer-submit-form',
  );

  const openCenteredSheet = (location) => {
    dialogVCGActions.openDialog();
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: 'creator_video_content_guidelines.presented',
      action_location: location,
    });
  };

  const openFullSheet = (isVCGBoolean, location) => {
    setIsVCG(isVCGBoolean);
    dialogFullActions.openDialog();
    trackEvent({
      event_type: EventType.IMPRESSION,
      event_name: isVCGBoolean
        ? 'creator_video_content_guidelines.presented'
        : 'creator_video_terms_and_conditions.presented',
      action_location: location,
    });
  };

  interface FormValues {
    firstName: string;
    lastName: string;
    email: string;
    socialProfile: string;
    submissions: CVSubmission[];
    termsConditions: boolean;
    contentGuidelines: boolean;
    marketingConsent: boolean;
  }

  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    formState: { isValid, errors: formErrors },
  } = useForm<FormValues>({
    mode: 'onBlur',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      socialProfile: '',
      termsConditions: true,
      contentGuidelines: true,
      marketingConsent: false,
      submissions: [] as CVSubmission[],
    },
  });

  const {
    fields: submissions,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'submissions',
  });

  const handleSuccessLogging = (actionLocation, fieldName) => {
    trackEvent(
      {
        event_type: EventType.OUTCOME,
        event_name: 'creator_video_submission.succeeded',
        action_location: actionLocation,
      },
      {
        form: {
          form_name: 'creator_video_submission',
          short_field_name: fieldName,
        },
      },
    );
  };

  const handleErrorLogging = (errors, field: string, actionLocation) => {
    trackEvent(
      {
        event_type: EventType.IMPRESSION,
        event_name: 'creator_video_submission.failed',
        action_location: actionLocation,
      },
      {
        error_list: errors.map((message) => ({
          error_description: message,
        })),
        form: {
          form_name: 'creator_video_submission',
          short_field_name: field,
        },
      },
    );
  };

  function handleUploadClick() {
    handleSuccessLogging('submission-form-upload-button', 'video upload process started');
    if (fileRef.current) {
      fileRef.current.click();
    }
  }

  function handleAttach(submission: CVSubmission) {
    if (uploadedFile) {
      append({ ...submission, uploadedFile });
    } else {
      append(submission);
    }
    handleSuccessLogging('submission-form-attach', 'attach video to form');
    fileRef.current!.value = '';
    setIsUploadDialogOpen(false);
    setIsLinkDialogOpen(false);
    setUploadedFile(null);
  }

  function getSourceValue(value: any): string {
    if (Object.values(Source).includes(value)) {
      return value.toUpperCase()
        + '_' + sessionStorage.getItem('tripStatus').toUpperCase();
    }
    return 'LANDING_PAGE';
  }

  async function handleUploadChange(e: React.ChangeEvent<HTMLInputElement>) {
    const input = e.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;

    setShowUploadError(false);

    if (!file) {
      console.log('No file selected');
      return;
    }

    console.log('File detected:', file.name, file.type);

    setUploadedFile(file);
    setPreviewTitle(file.name);

    const videoUrl = URL.createObjectURL(file);
    const videoElement = document.createElement('video');
    videoElement.autoplay = true;
    videoElement.muted = true;
    videoElement.playsInline = true;
    videoElement.src = videoUrl;

    try {
      const errors = await validateVideo(file, videoElement);
      if (errors.length > 0) {
        console.error('Validation errors:', errors);
        setErrorMessages(errors);
        URL.revokeObjectURL(videoUrl);
        handleErrorLogging(errors, 'upload video', 'submission-form-video-upload-error');
        return;
      }

      const thumbnailSrc = await getVideoThumbnail(videoElement);
      setPreviewThumbnail(thumbnailSrc);
      setIsUploadDialogOpen(true);

      console.log('Upload successful:', file.name);
      handleSuccessLogging('submission-form-video-upload', 'successful video upload');
    } catch (error) {
      console.error('An unexpected error occurred during file processing:', error);
    } finally {
      URL.revokeObjectURL(videoUrl);
    }
  }

  function handleUploadError(index: number) {
    setShowUploadError(true);
    setIsUploading(false);
    handleErrorLogging(
      ['There was an error uploading your video'],
      'upload video',
      'submission-form-video-upload-error',
    );
    remove(index);
  }

  async function onSubmit(data: any) {
    setIsLoading(true);

    logger.info('Submitting creator video form');

    const payload = {
      source: getSourceValue(sessionStorage.getItem('source')),
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      socialProfile: data.socialProfile,
      marketingConsent: data.marketingConsent,
      submissions: data.submissions.map((submission: any) => ({
        socialPlatform: submission.socialPlatform,
        externalVideoUrl: submission.externalVideoUrl,
        propertyName: submission.propertyName,
        propertyUrl: submission.propertyUrl,
        propertyId: submission.propertyId,
        destinationId: submission.destinationId,
        aspectRatio: '9/16',
        videoRef: submission.videoRef,
        adSetId: adSetId,
      })),
    };

    const response = await fetch(`${bffUrl}/api/creator-videos`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      logger.error('Failed to submit creator video form');
      setIsLoading(false);

      return;
    }

    logger.info('Creator video form submitted successfully');

    facebookAdvancedMatching([{em: payload.email, fn: payload.firstName, ln: payload.lastName }]);
    handleSuccessLogging('submit-form-button', 'submit form redirect');

    facebookLeadEvent([{ content_name: 'Creator Video Submission',  }]);
    payload.submissions.forEach(() => {
      facebookSubmitApplicationEvent([{}, {eventID: `EG-${payload.submissions.length}-${Math.floor(Math.random() * 30000)}`}]);
    })

    history.push('/en-us/creator-video/confirmation');
  }

  useEffect(() => {
    if (isUploadDialogOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isUploadDialogOpen]);

  const handleCloseUploadDialog = () => {
    setPreviewThumbnail(null);
    setIsUploadDialogOpen(false);
    setUploadedFile(null);
    fileRef.current!.value = '';
  };

  const handleCloseLinkDialog = () => {
    setIsLinkDialogOpen(false);
    fileRef.current!.value = '';
  };

  useEffect(() => {
    if (isLinkDialogOpen || isUploadDialogOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isLinkDialogOpen, isUploadDialogOpen]);

  return (
    <div className="CVSubmitForm" ref={innerRef}>
      <UitkLayoutFlex direction="column" space="twelve">
        <form onSubmit={handleSubmit(onSubmit)}>
          <UitkLayoutFlex direction="column" space="twelve">
            <UitkLayoutFlex direction="column" space="four">
              <UitkLayoutFlex className="CVInputs__name" space="four">
                <div>
                  <Controller
                    control={control}
                    name="firstName"
                    rules={{
                      required: 'Please enter your first name.',
                      minLength: { value: 3, message: 'First name must be at least 3 characters long.' },
                      maxLength: { value: 50, message: 'First name must be at most 50 characters long.' },
                      pattern: { value: /^[\p{L}\p{M}\p{Zs}.-]+$/u, message: 'First name is invalid.' },
                    }}
                    render={({ field }) => (
                      <UitkInput
                        label="First name"
                        autoComplete="off"
                        invalid={formErrors?.firstName && formErrors?.firstName?.message}
                        {...field}
                      />
                    )}
                  />
                </div>
                <div>
                  <Controller
                    control={control}
                    name="lastName"
                    rules={{
                      required: 'Please enter your last name.',
                      minLength: { value: 2, message: 'Last name must be at least 2 characters long.' },
                      maxLength: { value: 50, message: 'Last name must be at most 50 characters long.' },
                      pattern: { value: /^[\p{L}\p{M}\p{Zs}.-]+$/u, message: 'Last name is invalid.' },
                    }}
                    render={({ field }) => (
                      <UitkInput
                        label="Last name"
                        autoComplete="off"
                        invalid={formErrors?.lastName && formErrors?.lastName?.message}
                        {...field}
                      />
                    )}
                  />
                </div>
              </UitkLayoutFlex>

              <div>
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: 'Please enter your email address.',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: 'Please enter a valid email address.',
                    },
                  }}
                  render={({ field }) => (
                    <UitkInput
                      label="Email"
                      type="email"
                      required={true}
                      invalid={formErrors?.email && formErrors?.email?.message}
                      autoComplete="off"
                      {...field}
                    />
                  )}
                />
              </div>
              <input
                type="file"
                accept=".mp4,.mov"
                aria-hidden={true}
                ref={fileRef}
                hidden={true}
                onChange={handleUploadChange}
              />
              <UitkLayoutFlex direction="column" space="two">
                <Controller
                  control={control}
                  name="socialProfile"
                  rules={{
                    minLength: 5,
                    maxLength: 100,
                    required: 'Please enter your social profile.',
                    pattern: {
                      value: profileRegex,
                      message: 'We only accept links from Instagram, Facebook and TikTok',
                    },
                  }}
                  render={({ field }) => (
                    <UitkInput
                      label="Link to your social profile"
                      invalid={formErrors?.socialProfile && formErrors?.socialProfile?.message}
                      autoComplete="off"
                      {...field}
                    />
                  )}
                />
                <UitkText>
                  We support links from Instagram, Facebook and TikTok. Also ensure your account is publicly accessible.
                </UitkText>
              </UitkLayoutFlex>
            </UitkLayoutFlex>
            <UitkLayoutFlex direction="column" space="four">
              <UitkHeading size={6}>Videos</UitkHeading>
              {showUploadError && (
                <UitkBanner theme="error">
                  <UitkLayoutFlex alignItems="center" space="three">
                    <UitkIcon name="error" size={UitkIconSize.MEDIUM} />
                    <UitkLayoutFlex direction="column">
                      <UitkHeading size={7}>There was an error uploading your video.</UitkHeading>
                    </UitkLayoutFlex>
                  </UitkLayoutFlex>
                </UitkBanner>
              )}
              {errorMessages.length > 0 && (
                <UitkBanner theme="error">
                  <UitkLayoutFlex alignItems="center" space="three">
                    <UitkIcon name="error" size={UitkIconSize.MEDIUM} />
                    <UitkLayoutFlex direction="column">
                      <UitkHeading size={7}>There was an error uploading your video.</UitkHeading>
                      <UitkList size={3} className="CVErrors">
                        {errorMessages.map((message, index) => (
                          <UitkListItem key={index}>{message}</UitkListItem>
                        ))}
                      </UitkList>
                    </UitkLayoutFlex>
                  </UitkLayoutFlex>
                </UitkBanner>
              )}
              <UitkLayoutFlex alignItems="center" justifyContent="space-between" space="four">
                <UitkSecondaryButton
                  size={UitkButtonSize.LARGE}
                  isFullWidth={true}
                  onClick={handleUploadClick}
                  disabled={submissions.length >= 15}
                >
                  Upload video
                </UitkSecondaryButton>
                <UitkSecondaryButton
                  size={UitkButtonSize.LARGE}
                  isFullWidth={true}
                  onClick={() => {
                    handleSuccessLogging('submission-form-link-button', 'video link process started');
                    setIsLinkDialogOpen(true);
                  }}
                  disabled={submissions.length >= 15}
                >
                  Link a video
                </UitkSecondaryButton>
              </UitkLayoutFlex>
              <div className="CVDropzone">
                <UitkBadgeStandard size="small" className="CVDropzone__counter" theme="emphasis">
                  {submissions.length}/15
                </UitkBadgeStandard>
                {submissions.length > 0 ? (
                  <div className="CVSubmissions">
                    <div className="CVSubmissions__entry CVSubmissions__entry--placeholder" onClick={handleUploadClick}>
                      <div>
                        <UitkIcon name="upload" size={UitkIconSize.LARGE} />
                        <UitkText size={100}>Click to upload</UitkText>
                      </div>
                    </div>
                    {submissions.map((submission: any, index: number) => (
                      <div className="CVSubmissions__entry" key={submission.propertyUrl}>
                        <SubmitFormSubmission
                          key={index}
                          submission={submission}
                          onDeleteSubmission={() => remove(index)}
                          onUploadStart={() => setIsUploading(true)}
                          onUploadComplete={(url) => {
                            setValue(`submissions.${index}.videoRef`, url);
                            setIsUploading(false);
                          }}
                          onUploadError={() => handleUploadError(index)}
                        />
                      </div>
                    ))}
                  </div>
                ) : (
                  <UitkLayoutFlex
                    alignItems="center"
                    justifyContent="center"
                    direction="column"
                    space="four"
                    className="CVDropzone__guide"
                  >
                    <UitkLayoutFlex
                      direction="column"
                      space="two"
                      alignItems="center"
                      justifyContent="center"
                      onClick={handleUploadClick}
                    >
                      <UitkIcon name="upload" size={UitkIconSize.XLARGE} />
                      <UitkText size={200} weight="medium">
                        Tap to upload
                      </UitkText>
                    </UitkLayoutFlex>
                    <UitkLayoutFlex direction="column" space="two" alignItems="center" justifyContent="center">
                      <UitkList size={3} className="CVDropzone__rules">
                        <UitkListItem>Videos (.mp4 and .mov)</UitkListItem>
                        <UitkListItem>Videos should be 9:16 with minimum resolution of 1080*1920 pixels</UitkListItem>
                        <UitkListItem>Videos must be between 6 - 60 seconds</UitkListItem>
                        <UitkListItem>Max 500 MB for each video</UitkListItem>
                        <UitkListItem>You can share up to 15 videos</UitkListItem>
                      </UitkList>
                    </UitkLayoutFlex>
                  </UitkLayoutFlex>
                )}
              </div>
              <UitkLink>
                <UitkLayoutFlex alignItems="center" space="two">
                  <UitkTertiaryButton
                    alignLabelToContent="start"
                    className="CVVcg__button"
                    onClick={() =>
                      isMobile
                        ? openFullSheet(true, 'video-content-guidelines-hyperlink')
                        : openCenteredSheet('video-content-guidelines-hyperlink')
                    }
                  >
                    <UitkIcon name="help" size={UitkIconSize.SMALL} />
                    <UitkText className="CVTertiary__text">Video content guidelines</UitkText>
                  </UitkTertiaryButton>
                </UitkLayoutFlex>
              </UitkLink>
              <UitkLayoutFlex direction="column" space="four">
                <UitkText size={400}>
                  I acknowledge that I have complied with the video guidelines and that I own all rights to the content
                  or have a license to grant to Expedia Group, covering on and off platform use. Additionally, I
                  acknowledge that upon acceptance of my content, the terms and conditions shall apply. See our{' '}
                  <UitkTertiaryButton
                    className="CVVcg__button"
                    onClick={() => openFullSheet(false, 'terms-and-conditions-hyperlink')}
                  >
                    <UitkText className="CVTertiary__text" size={400}>
                      terms and conditions.
                    </UitkText>
                  </UitkTertiaryButton>
                </UitkText>
                <Controller
                  control={control}
                  name="marketingConsent"
                  render={({ field }) => (
                    <UitkCheckbox
                      id="marketingConsent"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e);
                        trigger('marketingConsent');
                        if (e.target.checked) {
                          trackEvent({
                            event_type: EventType.INTERACTION,
                            event_name: 'creator_video_marketing_opt_in.selected',
                            action_location: 'submission-form-marketing-checkbox',
                          });
                        } else {
                          trackEvent({
                            event_type: EventType.INTERACTION,
                            event_name: 'creator_video_marketing_opt_in.deselected',
                            action_location: 'submission-form-marketing-checkbox',
                          });
                        }
                      }}
                    >
                      I’d like to receive marketing emails from Expedia Group, including tips, advice, and access to
                      exclusive promotions and deals.
                    </UitkCheckbox>
                  )}
                />
              </UitkLayoutFlex>
            </UitkLayoutFlex>
            <UitkPrimaryButton
              size={UitkButtonSize.LARGE}
              isFullWidth={true}
              disabled={!isValid || !submissions.length || isLoading || isUploading}
              type="submit"
            >
              {isLoading ? <UitkLoader isDarkBackground isSmall /> : 'Submit'}
            </UitkPrimaryButton>
          </UitkLayoutFlex>
        </form>
      </UitkLayoutFlex>
      {isUploadDialogOpen && (
        <SubmitFormUploadDialog
          previewThumbnail={previewThumbnail}
          previewTitle={previewTitle}
          onClose={handleCloseUploadDialog}
          onAttach={handleAttach}
        />
      )}
      <Dialog
        dialogFullActions={dialogFullActions}
        dialogVCGActions={dialogVCGActions}
        centeredRef={desktopVideoContentGuidelinesTrigger}
        fullRef={fullSheetDialogTrigger}
        isVCG={isVCG}
        fullOpen={isFullDialogOpen}
        vcgOpen={isVCGDialogOpen}
        DialogVCGComponent={DialogVCGComponent}
        DialogFullComponent={DialogFullComponent}
      />
      {isLinkDialogOpen && <SubmitFormLinkDialog onClose={handleCloseLinkDialog} onAttach={handleAttach} />}
    </div>
  );
}
