import React, { useState } from 'react';

import { UitkTypeahead, ESSAdapter } from 'uitk-react-typeahead';

export function SubmitFormTypeahead({ onSelection, onDismiss }: any) {
  const [searchValue, setSearchValue] = useState('');

  const handleSelection = (selection: any) => {
    console.log(selection);
    onSelection(selection);
  };

  return (
    <UitkTypeahead
      adapter={
        new ESSAdapter({
          client: 'SearchForm',
          dest: false,
          domain: 'https://www.expedia.com/',
          expuserid: -1,
          features: 'consistent_display',
          guid: '',
          lob: 'HOTELS',
          locale: 'en_US', // Decides what language suggestions are returned in
          maxResults: 6,
          personalize: true,
          regionType: 128, // All regions types + hotels (matches HOTELS lob)
          siteId: 1,
        })
      }
      id="destination-typeahead"
      stid="destination-typeahead"
      // className="destination__typeahead"
      name="destination"
      icon="place"
      label="Work"
      value={searchValue}
      typeaheadPlaceholder="Search for a property"
      allowExactMatch={true}
      onDismiss={onDismiss}
      customTrigger={{
        showTypeahead: true,
      }}
      hasClearButton={true}
      handleClear={() => setSearchValue('')}
      onUpdate={handleSelection}
    />
  );
}
