import React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkTertiaryButton } from 'uitk-react-button';

export const TermsAndConditionsContent = ({ onGuidelinesClick }: { onGuidelinesClick?: () => void }) => (
  <UitkLayoutFlex justifyContent="center">
    <UitkLayoutFlex className="CVDialog__flex CV_documents" direction="column" space="four">
      <UitkHeading className="hide-desktop" size={2}>
        Terms & Conditions
      </UitkHeading>
      <UitkHeading className="visible-desktop--large" size={2}>
        Terms and Conditions
      </UitkHeading>
      <UitkText>Last revised: 03/31/2024</UitkText>
      <UitkText>
        Thank you for taking the time to read these important terms and conditions for submitting your videos to
        Expedia, Inc., on behalf of itself and its parents, affiliates, and subsidiaries (collectively, “Expedia”,
        “Expedia Group”, or “EG”).
      </UitkText>
      <UitkText>
        These terms are important as they, together with our applicable brand Terms of Service (“General Terms”), set
        out the legal terms on which any part of this website (the “Video Submission Site”) is made available to you.
        Your use of the Video Submission Site together with any other of our Service or Sites, including booking any
        Travel Service (each as defined in the General Terms) is conditioned upon your acceptance of each of these terms
        and the General Terms. The General Terms include an arbitration agreement and class action waiver that apply to
        claims brought against us. Please read them carefully.
      </UitkText>
      <UitkText>
        We may change these terms at any time and your future use of the Video Submission Site is subject to you
        accepting those updated terms by your continued use of the site. Updates to the terms shall only apply to video
        submissions made after an update.
      </UitkText>
      <ol className="CVDialog__ordered-list">
        <li>
          You acknowledge and agree that you have read, understood, and adhered to{' '}
          {onGuidelinesClick ? (
            <UitkTertiaryButton onClick={onGuidelinesClick}>EG’s Video Guidelines</UitkTertiaryButton>
          ) : (
            <UitkTertiaryButton tag="a" target="_blank" href="/en-us/creator-video/video-guidelines">
              EG’s Video Guidelines
            </UitkTertiaryButton>
          )}
          <UitkTertiaryButton onClick={onGuidelinesClick}>EG’s Video Guidelines</UitkTertiaryButton> found on the Video
          Submission Site for submitting videos for review and acceptance by EG.
        </li>
        <li>
          You acknowledge and agree your video submission includes all images, recordings (audio and/or audio visual),
          text, and/or other creative materials contained in the videos submitted through EG’s Video Submission Site
          (collectively, “Video”).
        </li>
        <li>
          You agree, represent, warrant and guarantee to EG that you are eighteen (18) years of age or older and: (a)
          you are solely responsible for the your Video; (b) your Video is your original work; (c) your Video and EG’s
          use of such Video, does not and will not infringe or violate any copyright, trademark, proprietary, privacy,
          publicity, terms of use, or other rights of any person or entity; and (d) you have and will maintain all
          necessary rights, consents, approvals, authorizations, and licenses to all content included in your Video to
          ensure that should EG accept your Video for use, EG will be able to use your Video and exercise the rights
          granted herein.
        </li>
        <li>
          You hereby grant to EG a nonexclusive, royalty free, fully paid, worldwide, perpetual, irrevocable,
          transferable and fully sublicensable (through multiple tiers) right and license to: (a) use, copy, reproduce,
          modify, adapt, publish, translate, create derivative works from, distribute, and publicly display and perform
          all Video posted or submitted through this Video Submission Site by you (whether before or after the date of
          grant of permission) throughout the world for any purpose in any media or form now known or hereafter devised;
          and (b) use the name that you submit in connection with any such Video, if necessary. To the extent allowable
          by applicable law, if you hold “moral rights” (including rights of attribution or integrity) in your Video,
          you expressly either (a) waive all “moral rights” that may subsist in your Video; or (b) expressly consent to
          the publication, use, modification, deletion, or exploitation of your Video by EG without regard to those
          “moral rights.” For any voice, image or likeness of any individual in your Video, you hereby grant to EG a
          nonexclusive, royalty free, fully paid, worldwide, perpetual, irrevocable, transferable, and fully
          sublicensable (through multiple tiers) right and license.
        </li>
        <li>
          You acknowledge and agree that upon EG’s acceptance of your Video, all rights, title, and interest in and to
          your Video, including all intellectual property rights therein, shall be transferred to EG pursuant to a Video
          Acceptance Agreement which you agree to sign. In exchange for accepting of your Video, EG shall make a payment
          to you as identified in the Video Acceptance Agreement, and such payment shall represent full and complete
          payment for transfer of ownership of such Video to EG. You are responsible for paying any applicable federal,
          provincial, state, local, income and withholding taxes, if any.
        </li>
        <li>
          You acknowledge and agree that upon EG’s acceptance of your Video, EG may use, edit, copy, add to, delete,
          adapt, translate, or otherwise modify the Video in any way EG sees fit, but EG shall have no obligation to use
          your Video.
        </li>
        <li>
          If your Video contains any personal data of individuals, you agree and warrant that you have obtained consent
          of these individuals for (a) their likeness and/or personal data to be included in the Video; and (b) the
          collection, disclosure, transfer, processing and use of their personal data by EG in accordance with these
          Terms and Conditions and our Privacy Policy (https://www.expediagroup.com/privacy-policy/default.aspx) . You
          further agree that if you are featured in the Video, you hereby grants to EG and its marketing affiliate
          (“Authorized Parties”), a non-exclusive, perpetual, irrevocable, worldwide license to use your name, social
          media links and/or handles, pseudonym, biographical facts, voice, statements, photograph and likeness
          (“collectively, “Personal Material”), alone or combined with any material, in any media, now known or
          hereafter developed, in connection with and for EG’s marketing, promotional and advertising purposes. You
          understands that if they are a resident of the EU, UK, Brazil, Thailand, or other relevant jurisdiction, you
          may the right to withdraw your consent at any time to the use of your Personal Materials by contacting EG via
          PrivacyTeam@expedia.com.
        </li>
        <li>
          You acknowledge and agree that should your Video be selected by EG, you grant EG exclusive rights in the
          Video, and you shall not enter into any agreement with another online travel agency or person and offer the
          same Video to any such third party.
        </li>
        <li>These terms are governed by United States federal law and the laws of the State of Washington.</li>
      </ol>
    </UitkLayoutFlex>
  </UitkLayoutFlex>
);
