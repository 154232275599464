import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface LinkWithQueryProps {
  children: React.ReactNode;
  to: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const LinkWithQuery = ({ children, to, ...props }: LinkWithQueryProps) => {
  const { search } = useLocation();

  return (
    <Link to={to + search} {...props}>
      {children}
    </Link>
  );
};
