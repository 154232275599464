import { useState, useEffect } from 'react';

function useDevice() {
  const defaultWidth = 800;
  const defaultHeight = 600;

  const [screenSize, setScreenSize] = useState({
    width: typeof window !== 'undefined' ? window.innerWidth : defaultWidth,
    height: typeof window !== 'undefined' ? window.innerHeight : defaultHeight,
  });
  const [isMobile, setIsMobile] = useState(screenSize.width < 768);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setScreenSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        setIsMobile(window.innerWidth < 768);
      };
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  return { isMobile, screenSize };
}

export { useDevice };
