import * as React from 'react';

import { Link } from 'react-router-dom';
import { UitkButtonSize, UitkPrimaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkSheetContent } from 'uitk-react-sheet';
import { Footer, FacebookPixelScript } from 'components/creator-video';
import { useCommonClickStreamEvent, EventType } from 'src/util';
import { LinkWithQuery } from 'components/shared';
import { useLocation, useHistory, } from 'react-router-dom';

const navItems = [
  {
    label: 'Benefits',
    anchor: '#benefits',
  },
  {
    label: 'Inspiration',
    anchor: '#inspiration',
  },
  {
    label: 'How it works',
    anchor: '#how-it-works',
  },
  {
    label: 'Tips',
    anchor: '#tips',
  },
  {
    label: 'FAQ',
    anchor: '#faq',
  },
];

export function Layout({ children }: any) {
  const location = useLocation();
  const history = useHistory();
  const [mobileMenuVisible, setMobileMenuVisible] = React.useState(false);
  const triggerMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };
  const { trackEvent } = useCommonClickStreamEvent();

  const isSubmitPage = location.pathname.includes('submit');

  const handleAnchorClick = ({ event, anchor }) => {
    event.preventDefault();
    let currentUrl = window.location.href;
    currentUrl = currentUrl.split('#')[0];
    const anchorText = anchor.replace('#', '');
    trackEvent(
      {
        event_type: EventType.INTERACTION,
        event_name: 'creator_video_redirect_to_section.selected',
        action_location: `header-nav-${anchorText}`,
      },
      { additional_context: { user_interface: { component_name: 'header-nav', component_element_text: anchorText } } },
    );
    triggerMobileMenu();
    if (currentUrl.endsWith('creator-video/') || currentUrl.endsWith('creator-video')) {
      history.push(`${anchor}`);
    } else {
      history.push(`/en-us/creator-video/${anchor}`);
    }
  };

  // scroll page to top when navigating
  React.useEffect(() => {
    if (location.pathname) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [location.pathname]);

  return (
    <div className="CVLayout">
      <FacebookPixelScript />
      <header className="CVHeader">
        <div className="CVHeader__container">
          <Link to="/en-us/creator-video" className="CVHeader__logo">
            <img src="/images/logos/eg-affiliate-program-logo-blue.svg" alt="Expedia Group Affiliate Program" />
          </Link>
          <nav className="CVHeader__nav">
            {navItems.map((link, ind) => {
              return (
                <a
                  onClick={(event) => handleAnchorClick({ event, anchor: link.anchor })}
                  key={`creator-video-nav-${ind}`}
                >
                  {link.label}
                </a>
              );
            })}
          </nav>
          <UitkLayoutFlex direction="row" className="CVHeader__Utils">
            {!isSubmitPage && (
              <LinkWithQuery to="/en-us/creator-video/submit">
                <UitkPrimaryButton
                  className="CVHeader__submit"
                  onClick={() =>
                    trackEvent(
                      {
                        event_type: EventType.INTERACTION,
                        event_name: 'creator_video_go_to_submission.selected',
                        action_location: 'header-nav',
                      },
                      { additional_context: { user_interface: { component_element_text: 'desktop header button' } } },
                    )
                  }
                  size={UitkButtonSize.LARGE}
                >
                  Submit videos
                </UitkPrimaryButton>
              </LinkWithQuery>
            )}
            <UitkTertiaryButton className="CVHeader__hamburger" onClick={triggerMobileMenu}>
              {mobileMenuVisible ? (
                <UitkIcon name="close" size={UitkIconSize.MEDIUM} className="CVHeader__mobile-menu" />
              ) : (
                <UitkIcon name="menu" size={UitkIconSize.MEDIUM} className="CVHeader__mobile-menu" />
              )}
            </UitkTertiaryButton>
          </UitkLayoutFlex>
        </div>
      </header>
      {mobileMenuVisible && (
        <div className="CVPopover__mobile-menu">
          <UitkSheetContent padded className="CVHeader_mobile-sheet-padding">
            <UitkLayoutFlex className="CVPopover__flex" justifyContent="space-between" direction="column">
              <nav className="CVHeader__mobile-nav">
                <a href="/en-us/creator-video">Home</a>
                {navItems.map((link, ind) => {
                  return (
                    <a
                      href={link.anchor}
                      onClick={(event) => handleAnchorClick({ event, anchor: link.anchor })}
                      key={`creator-video-nav-${ind}`}
                    >
                      {link.label}
                    </a>
                  );
                })}
              </nav>
            </UitkLayoutFlex>
          </UitkSheetContent>
        </div>
      )}
      {children}
      <Footer />
    </div>
  );
}
