import React from 'react';

import { UitkCard, UitkCardContentSection } from 'uitk-react-cards';
import { UitkText } from 'uitk-react-text';
import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkLink } from 'uitk-react-link';
import { UitkIcon, UitkIconSize } from 'uitk-react-icons';
import { UitkLineSkeleton } from 'uitk-react-skeleton';
import { UitkSpacing } from 'uitk-react-spacing';

interface SubmitFormHotelCardProps {
  propertyUrl: string;
  hotelName: string;
  location: string;
  onEdit: () => void;
  isLoading: boolean;
}

export function SubmitFormHotelCard({ isLoading, propertyUrl, hotelName, location, onEdit }: SubmitFormHotelCardProps) {
  return (
    <UitkCard border>
      <UitkCardContentSection padded={false}>
        <UitkCardContentSection padded>
          <UitkSpacing padding={{ inline: 'two' }}>
            <UitkLayoutFlex direction="column" space="three">
              <UitkLayoutFlex direction="column" space="one">
                {isLoading ? (
                  <div style={{ position: 'relative', width: '100px' }}>
                    <UitkLineSkeleton />
                  </div>
                ) : (
                  <UitkText size={300} weight="medium">
                    {hotelName}
                  </UitkText>
                )}
                {isLoading ? (
                  <div style={{ position: 'relative', width: '200px' }}>
                    <UitkLineSkeleton />
                  </div>
                ) : (
                  <UitkText size={100}>{location}</UitkText>
                )}
              </UitkLayoutFlex>
              <UitkLayoutFlex alignItems="center" space="six" className="CVHotelPreview__actions">
                <UitkLink onClick={onEdit} size="small" inline disabled={isLoading}>
                  <span>
                    <UitkIcon name="mode_edit" size={UitkIconSize.EXTRA_SMALL} />
                    Edit
                  </span>
                </UitkLink>
                <UitkLink size="small" inline disabled={isLoading}>
                  <a href={propertyUrl} target="_blank" rel="noreferrer">
                    <UitkIcon name="open_in_new" size={UitkIconSize.EXTRA_SMALL} />
                    Preview
                  </a>
                </UitkLink>
              </UitkLayoutFlex>
            </UitkLayoutFlex>
          </UitkSpacing>
        </UitkCardContentSection>
      </UitkCardContentSection>
    </UitkCard>
  );
}
