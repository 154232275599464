import * as React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';

export interface CarouselProps {
  children: any;
  className?: string;
}

export function Carousel({ children: children, className }: CarouselProps) {
  return (
    <UitkLayoutFlex className={`CVCarousel__${className}`} direction="row">
      {children}
    </UitkLayoutFlex>
  );
}
