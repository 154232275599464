import { supportedLocales } from '../config/supported-locales';

const localeFormat = /[a-zA-Z]{2}[-_][a-zA-Z]{2}/;

export const makeLocaleParam = (locale: string) => {
  if (!locale || !locale.match(localeFormat)) {
    return supportedLocales[0].langCode;
  }

  return locale.toLowerCase().replace('_', '-');
};
