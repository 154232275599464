import * as React from 'react';

import { useState, useEffect } from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkFigure, UitkImage } from 'uitk-react-images';

import { CVSubmission } from 'models';
import { UitkText } from 'uitk-react-text';
import { UitkBadgeStandard } from 'uitk-react-badge';
import { UitkIcon } from 'uitk-react-icons';
import { UitkButtonSize, UitkTertiaryButton } from 'uitk-react-button';
import { UitkLoader } from 'uitk-react-loader';

import { useStores, useLogger } from 'hooks';
import { useCommonClickStreamEvent, EventType } from 'src/util';

interface SubmitFormSubmissionProps {
  submission: CVSubmission;
  onUploadStart: () => void;
  onDeleteSubmission: () => void;
  onUploadComplete: (url: string) => void;
  onUploadError: () => void;
}

export function SubmitFormSubmission({
  submission,
  onUploadStart,
  onDeleteSubmission,
  onUploadComplete,
  onUploadError,
}: SubmitFormSubmissionProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { trackEvent } = useCommonClickStreamEvent();

  const logger = useLogger();

  const { uiStore } = useStores();

  const bffUrl = uiStore.bffUrl;

  useEffect(() => {
    let isMounted = true;

    const uploadVideo = async () => {
      if (!submission.uploadedFile) {
        return;
      }

      setIsLoading(true);
      onUploadStart();

      logger.info('Fetching S3 URL for video upload');

      const response = await fetch(`${bffUrl}/api/creator-videos/get-s3-url`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fileName: submission.uploadedFile.name,
          fileType: submission.uploadedFile.type,
        }),
      });

      if (!response.ok) {
        logger.error('Failed to get S3 URL for video upload');
        onUploadError();
        return;
      }

      const s3Url = await response.json();

      logger.info('Uploading video to S3');

      const uploadRes = await fetch(s3Url.url, {
        method: 'PUT',
        headers: {
          'Content-Type': submission.uploadedFile.type,
        },
        body: submission.uploadedFile,
      });

      if (!uploadRes.ok) {
        logger.error('Failed to upload video to S3');
        onUploadError();
        return;
      }

      onUploadComplete(s3Url.key);

      logger.info('Video uploaded to S3');

      if (isMounted) {
        setIsLoading(false);
      }

      trackEvent(
        {
          event_type: EventType.OUTCOME,
          event_name: 'creator_video_submission.succeeded',
          action_location: 'submission-form',
        },
        {
          form: {
            form_name: 'creator_video_submission',
            short_field_name: 'upload video file to s3',
          },
        },
      );
    };

    uploadVideo();

    return () => {
      isMounted = false;
    };
  }, [submission.uploadedFile]);

  return (
    <UitkLayoutFlex className="CVSubmission" direction="column" space="three">
      {!submission.externalVideoUrl ? (
        <UitkFigure className="CVSubmission__thumb">
          <UitkImage
            src={submission.thumbUrl}
            alt={submission.title}
            className={`CVSubmission__image ${isLoading ? 'CVSubmission__image--loading' : ''}`}
          />
          {isLoading && <UitkLoader className="CVSubmission__loader" isDarkBackground isSmall={true} />}
          {!isLoading && (
            <UitkTertiaryButton
              className="CVSubmission__delete"
              size={UitkButtonSize.SMALL}
              onClick={onDeleteSubmission}
            >
              <UitkIcon name="delete" />
            </UitkTertiaryButton>
          )}
          <div className="CVSubmission__scrim"></div>
          <UitkBadgeStandard className="CVSubmission__badge" size="small">
            Video
          </UitkBadgeStandard>
        </UitkFigure>
      ) : (
        <div
          className={`CVSubmission__thumb CVSubmission__thumb--${submission.socialPlatform} ${
            isLoading ? 'CVSubmission__thumb--loading' : ''
          }`}
        >
          {isLoading && <UitkLoader className="CVSubmission__loader" isDarkBackground isSmall={true} />}
          {!isLoading && (
            <UitkTertiaryButton
              className="CVSubmission__delete"
              size={UitkButtonSize.SMALL}
              onClick={onDeleteSubmission}
            >
              <UitkIcon name="delete" />
            </UitkTertiaryButton>
          )}
          <img alt="social media logo" src={`/images/logo/${submission.socialPlatform}.svg`} />
          <UitkBadgeStandard className="CVSubmission__badge" size="small">
            Link
          </UitkBadgeStandard>
        </div>
      )}
      <UitkLayoutFlex direction="column">
        <UitkText overflow="truncate" size={200} weight="medium" className="CVSubmission__title">
          {submission.propertyName}
        </UitkText>
        <UitkText overflow="truncate" size={200} weight="medium" className="CVSubmission__filename">
          {submission.externalVideoUrl || submission.title}
        </UitkText>
      </UitkLayoutFlex>
    </UitkLayoutFlex>
  );
}
