export function getVideoThumbnail(videoElement: HTMLVideoElement): Promise<string> {
  return new Promise((resolve) => {
    const captureFrame = () => {
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

      const imgSrc = canvas.toDataURL('image/jpeg');
      resolve(imgSrc);
    };

    videoElement.addEventListener('loadeddata', () => {
      videoElement.currentTime = 0.1;
    });

    videoElement.addEventListener('seeked', () => {
      setTimeout(captureFrame, 100);
    });
  });
}
