import * as React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkButtonSize, UitkPrimaryButton, UitkTertiaryButton } from 'uitk-react-button';
import { UitkSheet, UitkSheetContent } from 'uitk-react-sheet';
import { UitkToolbar } from 'uitk-react-toolbar';
import { useStores } from 'hooks';
import { useDevice } from 'src/util';

import { VideoGuidelinesContent } from './video-guidelines-content';
import { TermsAndConditionsContent } from './tnc-content';

export function Dialog({
  dialogVCGActions,
  dialogFullActions,
  isVCG,
  isVCGDialogOpen,
  isFullDialogOpen,
  desktopVideoContentGuidelinesTrigger,
  fullSheetDialogTrigger,
  DialogVCGComponent,
  DialogFullComponent,
}: any) {
  const { isMobile } = useDevice();
  const { uiStore } = useStores();

  const closeCenteredSheet = () => {
    dialogVCGActions.closeDialog();
  };

  const closeFullSheet = () => {
    if (uiStore.additionalParams === 'showCVDialog') {
      uiStore.setAdditionalParams('');
    }
    dialogFullActions.closeDialog();
  };

  const [switchDialog, setSwitchDialog] = React.useState(false);

  const handleSwitchDialog = (bool) => {
    setSwitchDialog(bool);
    if (bool) {
      isMobile ? dialogFullActions.openDialog() : dialogVCGActions.openDialog();
    } else {
      if (!isMobile) {
        dialogVCGActions.closeDialog();
      }
      dialogFullActions.openDialog();
    }
  };

  const videoContentGuidelinesBody = () => (
    <div>
      <UitkLayoutFlex direction="column" space="six" className="CVDialog__flex">
        {switchDialog && (
          <UitkTertiaryButton onClick={() => handleSwitchDialog(false)}>
            Back to Terms and Conditions
          </UitkTertiaryButton>
        )}
        <VideoGuidelinesContent />
        <UitkPrimaryButton isFullWidth={true} size={UitkButtonSize.LARGE} onClick={closeFullSheet}>
          Done
        </UitkPrimaryButton>
      </UitkLayoutFlex>
    </div>
  );

  const termsAndConditionsBody = () => (
    <>
      <TermsAndConditionsContent onGuidelinesClick={() => handleSwitchDialog(true)} />
      <UitkPrimaryButton isFullWidth={true} size={UitkButtonSize.LARGE} onClick={closeFullSheet}>
        Done
      </UitkPrimaryButton>
    </>
  );

  return (
    <>
      <DialogVCGComponent>
        <UitkSheet
          className="CVDialog__centered-sheet"
          isVisible={isVCGDialogOpen || switchDialog}
          triggerRef={desktopVideoContentGuidelinesTrigger}
          type="centered"
        >
          <UitkToolbar
            navigationContent={{
              onClick: closeCenteredSheet,
              navIconLabel: 'Close video content guidelines',
            }}
          />
          <UitkSheetContent>{videoContentGuidelinesBody()}</UitkSheetContent>
        </UitkSheet>
      </DialogVCGComponent>
      <DialogFullComponent>
        <UitkSheet
          className="CVDialog__full-sheet"
          isVisible={isFullDialogOpen}
          triggerRef={fullSheetDialogTrigger}
          type="full"
        >
          <UitkToolbar
            navigationContent={{
              onClick: closeFullSheet,
              navIconLabel: 'Close full dialog',
            }}
            toolbarTitle={isVCG ? '' : 'Terms & conditions'}
          />
          <UitkSheetContent className={`CVSheet__margin-auto CVSheet__content`}>
            <div>{isVCG || switchDialog ? videoContentGuidelinesBody() : termsAndConditionsBody()}</div>
          </UitkSheetContent>
        </UitkSheet>
      </DialogFullComponent>
    </>
  );
}
