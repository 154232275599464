import * as React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';

import { Carousel, InspirationDisplayVideo } from 'components/creator-video';

export function Inspiration() {
  const [inspirationVideos] = React.useState([
    {
      videoUrl: 'https://console.vap.expedia.com/affiliate-content-creator/content/wenCx-travel-video-1.mp4',
      poster: '/images/travel-video-poster-1.png',
    },
    {
      videoUrl: 'https://console.vap.expedia.com/affiliate-content-creator/content/ldIuy-travel-video-2.mp4',
      poster: '/images/travel-video-poster-2.png',
    },
    {
      videoUrl: 'https://console.vap.expedia.com/affiliate-content-creator/content/PDoaz-travel-video-3.mp4',
      poster: '/images/travel-video-poster-3.png',
    },
    {
      videoUrl: 'https://console.vap.expedia.com/affiliate-content-creator/content/UHk8L-travel-video-4.mp4',
      poster: '/images/travel-video-poster-4.png',
    },
  ]);

  return (
    <div className="CVContainer">
      <UitkLayoutFlex
        direction="column"
        alignItems="center"
        className="CVSection__padding CVGap-64 CVSection__width"
        id="inspiration"
      >
        <UitkLayoutFlex direction="column" tag="header" className="CVGap-16">
          <UitkText size={400} weight="medium" align="center">
            Inspiration
          </UitkText>
          <UitkHeading size={2} tag="h2" align="center">
            Videos to get you inspired
          </UitkHeading>
        </UitkLayoutFlex>
        <div className="CVInspiration__example-carousel">
          <Carousel className="inspiration">
            {inspirationVideos.map((obj, ind) => (
              <div className="CVCarousel__flex-item" key={`cv-inspiration__carousel-item-${ind}`}>
                <InspirationDisplayVideo {...obj} />
              </div>
            ))}
          </Carousel>
        </div>

        <div className="CVInspiration__example-flex">
          {inspirationVideos.map((obj, ind) => (
            <InspirationDisplayVideo key={`cv-inspiration__flex-item-${ind}`} {...obj} />
          ))}
        </div>
      </UitkLayoutFlex>
    </div>
  );
}
