import {
    useLoggerEvents,
    SystemEvent,
    SystemEventLevel,
} from '@shared-ui/logger-context';

const LOG_EVENTS = {
    INFO_EVENT: new SystemEvent(SystemEventLevel.INFO, 'INFO_EVENT'),
    WARNING_EVENT: new SystemEvent(SystemEventLevel.WARN, 'WARNING_EVENT'),
    ERROR_EVENT: new SystemEvent(SystemEventLevel.ERROR, 'ERROR_EVENT'),
};

export const useLogger = () => {
    const events = useLoggerEvents(LOG_EVENTS);

    const info = (message: string) => {
      events.INFO_EVENT.log(message);
    };

    const warn = (message: string) => {
      events.WARNING_EVENT.log(message);
    };

    const error = (message: string) => {
      events.ERROR_EVENT.log(message);
    };

    return {
      info,
      warn,
      error,
    };
};
