import * as React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkFigure } from 'uitk-react-images';
import { UitkIcon } from 'uitk-react-icons';
import { UitkButtonSize, UitkOverlayButton } from 'uitk-react-button';

import { useCommonClickStreamEvent, EventType } from 'src/util';

export interface InspirationDisplayVideoProps {
  videoUrl: string;
  poster: string;
}

export function InspirationDisplayVideo({ videoUrl, poster }: InspirationDisplayVideoProps) {
  const videoRef = React.useRef(null);
  const { trackEvent } = useCommonClickStreamEvent();

  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isMuted, setIsMuted] = React.useState(true);

  React.useEffect(() => {
    if (isPlaying && videoRef.current) {
      trackEvent(
        {
          event_type: EventType.IMPRESSION,
          event_name: 'creator_video_media.presented',
          action_location: 'inspiration-video'
        },
        {
          additional_context: {
            user_interface: {
              component_name: 'inspiration video is played',
              component_element_name: `${videoRef.current?.src} video`
            }
          }
        });
    }
  }, [isPlaying, videoRef?.current]);

  function togglePlay() {
    setIsPlaying(!isPlaying);
    videoRef.current[isPlaying ? 'pause' : 'play']();
  }

  function toggleMute() {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  }

  return (
    <UitkLayoutFlex className="CVInspiration__example-card" direction="column">
      <div>
        <UitkFigure className="CVInspiration_display-video">
          <video
            role="video"
            poster={poster}
            playsInline
            src={videoUrl}
            style={{ width: '100%', position: 'relative' }}
            ref={videoRef}
            muted={isMuted}
          />
          <UitkLayoutFlex direction="row" className="CVInspiration__overlay-buttons" justifyContent="space-between">
            <UitkOverlayButton onClick={togglePlay} size={UitkButtonSize.SMALL} className="CVInspiration__overlay">
            {isPlaying ? (
              <>
                <UitkIcon name="pause" /> Pause
              </>
            ) : (
              <>
                <UitkIcon name="play_arrow" /> Play
              </>
            )}
            </UitkOverlayButton>
            <UitkOverlayButton
              onClick={toggleMute}
              size={UitkButtonSize.SMALL}
              className="CVInspiration__overlay"
              aria-label="play"
            >
              {isMuted ? (
                <>
                  <UitkIcon name="volume_off" />
                </>
              ) : (
                <>
                  <UitkIcon name="volume_up" />
                </>
              )}
            </UitkOverlayButton>
          </UitkLayoutFlex>
        </UitkFigure>
      </div>
    </UitkLayoutFlex>
  );
}
