import * as React from 'react';

import { EventType, useCommonClickStreamEvent } from 'src/util';

export interface HeroVideoProps {
  url: string;
  poster: string;
}
const HeroVideo = ({ url, poster }: HeroVideoProps) => {
  const videoRef = React.useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = React.useState(true);
  const { trackEvent } = useCommonClickStreamEvent();

  const handleVideoClick = () => {
    setIsVideoPlaying(!isVideoPlaying);
    videoRef.current[isVideoPlaying ? 'pause' : 'play']();
  };

  React.useEffect(() => {
    if (isVideoPlaying && videoRef.current) {
      trackEvent({
          event_type: EventType.IMPRESSION,
          event_name: 'creator_video_media.presented',
          action_location: 'hero-video'
        },
        {
          additional_context: {
            user_interface: {
              component_name: 'hero video is played',
              component_element_name: `${videoRef.current?.src} video`
            }
          }
        }
      );
    }
  }, [isVideoPlaying, videoRef?.current]);

  return (
    <video
      autoPlay
      role="video"
      ref={videoRef}
      poster={poster}
      style={{ width: '100%', position: 'relative' }}
      onClick={handleVideoClick}
      loop
      muted
      preload="auto"
      playsInline
    >
      <source src={url} type="video/mp4" />
    </video>
  );
};
export { HeroVideo };
