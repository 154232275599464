import { Logger, SystemEvent, SystemEventLevel } from 'bernie-logger';

export default class AffiliateHubLogger implements Logger {
  private logger: Logger;

  constructor(logger: Logger) {
    this.logger = logger;
  }

  public logEvent(systemEvent: SystemEvent, ...args: object[]): void {
    this.logger.logEvent(systemEvent, ...args);
  }

  private logAtLevel(level: SystemEventLevel, event: string, args: object[]) {
    this.logEvent(new SystemEvent(level, event), ...args);
  }

  public critical(event, ...args: object[]) {
    this.logAtLevel(SystemEventLevel.CRITICAL, event, args);
  }

  public error = (event: string, ...args: object[]) => {
    this.logAtLevel(SystemEventLevel.ERROR, event, args);
  };

  public warn = (event, ...args: object[]) => {
    this.logAtLevel(SystemEventLevel.WARN, event, args);
  };

  public info = (event, ...args: object[]) => {
    this.logAtLevel(SystemEventLevel.INFO, event, args);
  };
}
