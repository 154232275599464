import React from 'react';

import { UitkLayoutFlex } from 'uitk-react-layout-flex';
import { UitkHeading, UitkText } from 'uitk-react-text';
import { UitkList } from 'uitk-react-list';

export const VideoGuidelinesContent = () => (
  <UitkLayoutFlex direction="column" space="six" className="CVDialog__flex">
    <UitkHeading size={2}>Video Guidelines</UitkHeading>
    <UitkText size={400}>Last revised: 06/28/2024</UitkText>
    <UitkText size={400}>
      We recognize how important it is that our platform provides a safe environment for everyone in the travel
      community, and that the contributions on our site provide useful and relevant guidance that helps travelers plan
      their trips.
    </UitkText>
    <UitkText size={400}>
      All accepted and posted videos must meet these requirements. The Video Guidelines (“Video Guidelines”) cover the
      rules for video submissions uploaded or shared to Expedia Group. Please note that these Video Guidelines may be
      modified from time to time, so you’re encouraged to check back for updates.
    </UitkText>
    <UitkHeading size={4}>Key Requirements</UitkHeading>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Relevant to other travelers:</UitkText>
      <UitkText size={400}>
        All videos must have relevance to an accommodation.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Video Duration:</UitkText>
      <UitkText size={400}>
        The ideal video length is 6-30 seconds with the maximum allowed length of 60 seconds and a minimum allowed
        length of 6 seconds.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Video Format:</UitkText>
      <UitkText size={400}>Acceptable video formats include mp4 or mov, with a preference for mp4.</UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Audio:</UitkText>
      <UitkText size={400}>
        Only non-copyrighted music will be accepted. No
        other sound effects are allowed; any other added music will be removed.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Video Size:</UitkText>
      <UitkText size={400}>Videos must not exceed 500 MB. </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Video Resolution:</UitkText>
      <UitkText size={400}>
        Videos should have a minimum frame rate of 30 FPS (frames per second) and minimum resolution of 1080*1920
        pixels. Expedia does not support 4K or higher resolutions. If you upload a 4K video to Expedia, it will be
        compressed and cropped.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Video Orientation:</UitkText>
      <UitkText size={400}>All videos must be filmed in portrait mode (9:16).</UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>License and Rights to Publicity of Content:</UitkText>
      <UitkText size={400}>
        By submitting a video of any kind, you hereby grant to Expedia Group and its affiliates a nonexclusive, royalty
        free, fully paid, worldwide, perpetual, irrevocable, transferable and fully sublicensable (through multiple
        tiers) right and license to: (1) use, copy, reproduce, modify, adapt, publish, translate, create derivative
        works from, distribute, and publicly display and perform all Video posted or submitted to Expedia Group by you
        (whether before or after the posting of these Video Guidelines) throughout the world for any purpose in any
        media or form now known or hereafter devised. For any voice, image or likeness of any individual in your video,
        you hereby grant to Expedia Group a nonexclusive, royalty free, fully paid, worldwide, perpetual, irrevocable,
        transferable, and fully sublicensable (through multiple tiers) right and license.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Ownership:</UitkText>
      <UitkText size={400}>
        Upon Expedia Group’s acceptance of your video, you hereby transfer all rights, title and interest in and to your
        video to Expedia Group.
      </UitkText>
    </UitkLayoutFlex>
    <UitkHeading size={4}>Creative guidelines</UitkHeading>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Engagement:</UitkText>
      <UitkText size={400}>
        Create engaging and informative videos showcasing unique aspects of the property and surroundings.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Primary Focus:</UitkText>
      <UitkText size={400}>Video primary focus should be on the property.</UitkText>
      <UitkList>
        <li>
          <UitkText size={500}>Property + amenities:</UitkText> Videos should be of the property and can include the
          surrounding area. Include 2-3 different areas of the property like:
          <UitkList>
            <li>Hotel (Exterior landscape, view, interior, lobby, lounge, rooms, suites)</li>
            <li>Amenities (Pools, spas, gyms, dining)</li>
          </UitkList>
        </li>
      </UitkList>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Perspectives:</UitkText>
      <UitkText size={400}>
        Capture the different aspects of the stay from a variety of angles and distances (such as at eye level, from
        above, from far away and up close). Do not capture extreme angles like 360s, inverted angles and refrain from
        using distorted lens like fisheye.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Lighting and Color:</UitkText>
      <UitkText size={400}>
        Use natural sunlight where available. If shooting indoors, rely on environmental lighting. Refrain from adding
        artificial lighting like spotlights, ring lights, or professional video/camera lighting to change the brightness
        of space. Natural color grade okay.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Talent:</UitkText>
      <UitkText size={400}>
        Ideally less than 30% of overall video should include talent, focus should remain on the hotel/destination. Be
        candid, positive, 1st or 3rd POV and wardrobe should fit to activity or environment. Refrain from too many
        selfies and unnatural posed moments, refrain from wardrobe that does not fit activity or environment like
        ballgown in the desert.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Food:</UitkText>
      <UitkText size={400}>
        Is a great passion for travelers but avoid showcasing too much food and beverages. Hotel dining rooms are okay
        but remember the destination/property is the hero. Highlight dining experience during stay, dining atmosphere,
        seating, location within hotel, popular and cultural foods, room service and in-room food perks. Refrain from
        tight shots that miss the broader dining experience, refrain from showcasing just food in video, be mindful of
        alcohol consumption and locations. Creators and all others in the video must be (or appear to be) 21 years of
        age or older if alcohol is featured. Alcohol in a bar or restaurant is okay, refrain from alcohol in casinos.
        Avoid promoting overconsumption by limiting alcoholic drinks to 1 per person featured (empty or full).
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Activities:</UitkText>
      <UitkText size={400}>
        Some hotels may offer activities or partner with businesses. Highlight activities found at hotel like yoga,
        pool/spa, recreational, and hotel partner business and activities like boat tours, cultural tours, etc.
        Highlight destination nature and culture like hikes, shopping, museums, architecture, history, zoos, etc. Where
        applicable, incorporate reasonable safety equipment (e.g., helmets) to address the risk of viewer who try and
        copy what they see. Refrain from animal tours, do not include photos/videos/references to unsafe or illegal
        activities (i.e., misuse of safety equipment in adventure activities, hiking in dangerous places, skiing
        out-of-bounds, etc.).
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Crafted and Aspirational:</UitkText>
      <UitkText size={400}>
        Ensure locations are elevated and bring out the best. Avoid spaces that feel too industrial, avoid mess and a
        too lived-in experience like messy beds or personal items.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Typography and Graphics: </UitkText>
      <UitkText size={400}>
        We do not want videos that have text, graphics, or emojis.
      </UitkText>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkHeading size={4}>Video Quality</UitkHeading>
      <UitkText size={400}>Video media should not be:</UitkText>
      <UitkList>
        <li>Low resolution, blurry, or pixelated.</li>
        <li>Graphically filtered with overlays, borders, or artistic effects.</li>
        <li>Too dark or light.</li>
        <li>Black and white or sepia.</li>
        <li>Sideways, upside down, or otherwise improperly rotated.</li>
        <li>No overt filters used.</li>
      </UitkList>
    </UitkLayoutFlex>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkText size={500}>Using a Phone to Capture Videos </UitkText>
      <UitkList>
        <li>Clean your lens to avoid unwanted smudges or dust.</li>
        <li>Use high quality settings on your phone.</li>
        <li>Stage the space (arrange it nicely for display, removing any unwanted items)</li>
        <li>Use smooth, steady movements to avoid shaky shots.</li>
        <li>Use plenty of light (avoid dark or backlit shots)</li>
        <li>Keep the phone orientation consistent.</li>
        <li>Limit using phone zoom to avoid degrading quality.</li>
      </UitkList>
    </UitkLayoutFlex>
    <UitkText>
      We strive to create a safe, helpful, and inclusive platform for everyone. By avoiding these prohibited elements,
      you contribute to a positive and welcoming community for all travelers.
    </UitkText>
    <UitkLayoutFlex className="CVDialog__vcg-flex-gap" direction="column">
      <UitkHeading size={4}>We do not accept videos that include:</UitkHeading>
      <div>
        <UitkText inline size={500}>
          Explicit Content -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Nudity, sexual acts, graphic violence, drug use, or excessive alcohol consumption.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Discriminatory Content -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Hate speech, racism, sexism, xenophobia, harassment, bullying.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Illegal Activity -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Violation of local laws or Expedia policies.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Unsafe Practices -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Risky behavior, dangerous stunts, or illegal activities.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Private Information -{' '}
        </UitkText>
        <UitkText inline size={400}>
          {`Unintentional exposure of others' identity without consent, including faces, license plates, or addresses.`}
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Promotional Content -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Direct advertising of Expedia competitor products or services.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Still Images in Video -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Videos comprised of still images in a slideshow or montage.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Misrepresentation -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Intentionally misleading viewers about the property or surrounding area.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Impersonation -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Attempts to impersonate another person, account, or entity, including Expedia representatives.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Infringement of Intellectual Property and Proprietary Rights -{' '}
        </UitkText>
        <UitkText
          inline
          size={400}
        >{`Content that infringes the intellectual property or other proprietary rights of others. Only submit your own content or content that you have permission to use. For example, unless you have obtained permission from all relevant third parties, do not play music or include images, artwork, or other audiovisual works in your video(s). Anyone featured in the video should avoid prominently visible branding on clothes, jewelry, or accessories if you don't have permission from the brand owner. `}</UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Confidential Information -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Any personally identifiable information about others, including full names, addresses, contact information,
          credit card numbers or other personally identifying information should not be displayed in the video.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Irrelevant Titles -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Video captions containing irrelevant information to the property listing type, style, or experience.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Symbols or Emojis -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Listing titles containing symbols or emojis.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Fraudulent Information -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Video content showcasing fraudulent, false, misleading, or deceptive information.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          External Links -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Video content with any web links, URLs, phone numbers or calls.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Property Access -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Videos containing specific information about accessing the property such as door codes, key locations, or
          exact addresses for vacation rental properties.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Branding & Tagging Celebrities -{' '}
        </UitkText>
        <UitkText inline size={400}>
          References to or footage containing other brands and logos, especially logos or branding for other travel
          agencies or Expedia competitors should not be displayed. #Hashtags with brand, celebrity or product names or
          tagging third-party companies or celebrities without prior agreement.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Alcohol and Alcohol Consumption –{' '}
        </UitkText>
        <UitkList>
          <li>Do not encourage, feature, or refer to behaviors that suggest irresponsible drinking.</li>
          <li>Do not encourage excessive consumption.</li>
          <li>
            Do not show alcohol more than what might be reasonably served to those in the video (i.e., one drink per
            person, no magnums for just 1-2 people, etc.)
          </li>
          <li>Do not disparage abstinence from alcoholic beverages.</li>
          <li>Do not show products by alcohol manufacturers or distributors.</li>
        </UitkList>
      </div>
      <div>
        <UitkText inline size={500}>
          Minors -{' '}
        </UitkText>
        <UitkText
          inline
          size={400}
        >{`Videos of guests under 18 years old will be excluded, unless they're accompanied by an adult.`}</UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Prices and Promotions -{' '}
        </UitkText>
        <UitkText inline size={400}>
          Videos containing prices, promotional information, discount claims, or time-sensitive information.
        </UitkText>
      </div>
      <div>
        <UitkText inline size={500}>
          Expedia Logos -{' '}
        </UitkText>
        <UitkText inline size={400}>
          {`Use of Expedia's intellectual property (such as logos and service names) unless you have received our express written consent.`}
        </UitkText>
      </div>
    </UitkLayoutFlex>
  </UitkLayoutFlex>
);
